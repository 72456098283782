<template>
	<v-container fluid class="pa-0">
		<v-data-table
			:headers="headers"
			:items="invoices"
			:items-per-page="15"
			class="elevation-1">
			<template v-slot:item="{ item }">
				<tr>
					<td>
						<a
							@click="
								$router.push({
									name: 'InvoiceUpdate',
									params: { id: item.id },
								})
							"
							>{{ item.id }}</a
						>
					</td>
					<td>{{ item.mtlTracking }}</td>
					<td>{{ getCustomerName(item.customerId) }}</td>
					<td>{{ getOptionName(item.typeId) }}</td>
					<td>$ {{ item.totalAmount }}</td>
					<td
						:class="{
							'green--text': checkIfPaid(item.paymentStatusId),
							'red--text': !checkIfPaid(item.paymentStatusId),
						}">
						{{ getOptionName(item.paymentStatusId) }}
					</td>
				</tr>
			</template></v-data-table
		>
	</v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
export default {
	name: "ShipmentList",
	mixins: [generalMixin],
	mounted: function () {
		this.getInvoices();
	},
	data() {
		return {
			headers: [
				{
					text: "Id",
					align: "start",
					value: "id",
				},
				{
					text: "Tracking #",
					align: "start",
					value: "mtlTracking",
				},
				{ text: "Customer", value: "customerId" },
				{ text: "Type", value: "typeId" },
				{
					text: "Total Amount",
					value: "totalAmount",
				},
				{ text: "Payment", value: "paymentStatusId" },
			],
			invoices: [],
		};
	},
	computed: {
		customers() {
			return this.$store.getters["customers"];
		},
		shipmentGroups() {
			return this.$store.getters["shipmentGroups"];
		},
		shipments: {
			get() {
				return this.$store.getters["shipments"];
			},
			set(val) {
				this.$store.commit("setShipments", val);
			},
		},
	},
	methods: {
		getInvoices() {
			backendService.getInvoices().then((response) => {
				this.invoices = response.data;
			});
		},
	},
};
</script>
<style>
.payment-paid {
	background-color: lightgreen;
}
</style>
