<template>
	<v-container
		fluid
		class="d-flex justify-space-between mt-5 white rounded-xl w-75">
		<div class="d-flex flex-column">
			<img
				class="image-preview rounded mx-0"
				:src="getProductPictureUrlById(image.id)"
				v-for="(image, idx) in images"
				:key="`img-${image.id}`"
				@click="selectImage(idx)" />
		</div>
		<div class="d-flex justify-center mx-2">
			<img
				class="image-view rounded-lg mx-0"
				:src="getProductPictureUrlById(selectedImageId)" />
		</div>
		<v-container class="d-flex flex-column">
			<h2 class="kh">{{ product.title }}</h2>
			<v-divider></v-divider>
			<div class="d-flex align-center">
				<h3 class="mr-2">Listed Price</h3>
				<h1>
					<span class="red--text"
						>$ {{ convertToDecimal(product.unitPrice) }}</span
					>
				</h1>
			</div>

			<div v-html="product.description" class="kh"></div>
		</v-container>
		<v-container class="d-flex justify-center">
			<v-card elevation="0" outlined width="300px" class="rounded-lg">
				<v-card-text class="d-flex flex-column">
					<div fluid class="d-flex justify-space-between">
						<h2
							class="green--text text--darken-1"
							v-if="product.quantity > 5">
							In Stock
						</h2>
						<h2
							class="orange--text text--darken-1"
							v-else-if="
								product.quantity <= 5 && product.quantity > 0
							">
							Only Few in Stock
						</h2>
						<h2
							class="red--text text--darken-1"
							v-else-if="product.quantity == 0">
							Out of Stock
						</h2>
						<a
							@click="
								$router.push({
									name: 'StorePage',
									id: product.storeId,
								})
							"
							><h2>{{ product.storeName }}</h2></a
						>
					</div>

					<p>
						Free delivery on orders
						<span class="font-weight-bold">over $50</span>
					</p>
				</v-card-text>
				<v-card-text>
					<v-autocomplete
						rounded
						dense
						outlined
						label="Quantity"
						v-model="qty"
						:items="[
							1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
						]"></v-autocomplete>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-around">
					<v-btn
						color="primary"
						rounded
						depressed
						@click="addToCart"
						:disabled="buyLoading"
						:loading="addLoading">
						<i class="fas fa-cart-plus mr-2"></i>
						Add to Cart
					</v-btn>
					<v-btn
						color="warning"
						rounded
						depressed
						@click="buyNow"
						:loading="buyLoading">
						<i class="fas fa-forward mr-2"></i>
						Buy Now
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import eventBus from "@/js/event-bus.js";
import generalMixin from "@/js/general-mixin";
export default {
	name: "ProductDetail",
	mixins: [generalMixin],
	mounted: function () {
		this.getProductById(this.$route.params.id);
		this.getProductPicturesByProductId(this.$route.params.id);
	},
	data() {
		return {
			qty: 1,
			product: {},
			images: [],
			addLoading: false,
			buyLoading: false,
			selectedImageId: null,
		};
	},

	computed: {
		cart() {
			return this.$store.getters["cart"];
		},
		cartCount() {
			return this.$store.getters["cartCount"];
		},
	},
	methods: {
		addToCart() {
			let cart = this.cart;
			let cartCount = this.cartCount;
			if (!cart[this.product.id]) {
				let item = { product: this.product, count: 0 };
				this.$set(cart, this.product.id, item);
			}
			cart[this.product.id].count += this.qty;
			this.$store.commit("setCart", cart);
			this.$store.commit("setCartCount", cartCount + this.qty);

			localStorage.setItem("cartCount", cartCount + this.qty);
			localStorage.setItem("cart", JSON.stringify(cart));

			this.addLoading = true;
			this.timer(1000).then(() => {
				this.addLoading = false;
				eventBus.$emit(
					"setSnackbar",
					"Item added to the cart",
					"success",
					true
				);
			});
		},
		buyNow() {
			let cart = this.cart;
			let cartCount = this.cartCount;
			if (!cart[this.product.id]) {
				let item = { product: this.product, count: 0 };
				this.$set(cart, this.product.id, item);
			}
			cart[this.product.id].count += this.qty;
			this.$store.commit("setCart", cart);
			this.$store.commit("setCartCount", cartCount + this.qty);

			localStorage.setItem("cartCount", cartCount + this.qty);
			localStorage.setItem("cart", JSON.stringify(cart));

			this.buyLoading = true;
			this.timer(1000).then(() => {
				this.buyLoading = false;
				eventBus.$emit(
					"setSnackbar",
					"Item added to the cart",
					"success",
					true
				);
				this.$router.push({ name: "ShoppingCart" });
			});
		},

		getProductById(id) {
			backendService.getProductById(id).then((response) => {
				this.product = response.data;
			});
		},
		getProductPicturesByProductId(productId) {
			backendService
				.getProductPicturesByProductId(productId)
				.then((response) => {
					this.images = response.data;
					if (this.images.length > 0) {
						this.selectedImageId = this.images[0].id;
					}
				});
		},
		getProductPictureUrlById(imageId) {
			if (!imageId) return;
			return backendService.getProductPictureUrlById(imageId);
		},
		selectImage(idx) {
			this.selectedImageId = this.images[idx].id;
		},
	},
};
</script>
<style scoped>
#item-id {
	font-size: 11px;
}
.image-preview {
	width: 50px;
	height: 50px;
	border: 1px solid gray;
	margin-right: 10px;
	margin-bottom: 5px;
	object-fit: contain;
	float: left;
	image-rendering: smooth;
}
.image-preview:hover {
	cursor: pointer;
	border: lightblue 5px solid;
}
.image-preview-mobile {
	width: 80px;
	height: 80px;
	border: 1px solid gray;
	margin-right: 10px;
	margin-bottom: 5px;
	object-fit: contain;
	image-rendering: smooth;
}
.image-view {
	width: 400px;
	height: 400px;
	border: 0px;
	object-fit: contain;
	image-rendering: auto;
}
.image-view-mobile {
	width: 100%;
	height: 350px;
	border: 0px;
	object-fit: contain;
	image-rendering: auto;
}
.x-scrollable {
	width: 100%;
	height: 80px;
	overflow-x: scroll;
	white-space: nowrap;
}
</style>
