<template>
	<v-dialog v-model="input" max-width="350">
		<in-stock-form
			:p-product="product"
			@set-product="
				(val) => {
					product = val;
				}
			"
			@handle-event="setDialog"></in-stock-form
	></v-dialog>
</template>
<script>
import InStockForm from "./InStockForm.vue";
export default {
	name: "InStockDialog",
	components: {
		"in-stock-form": InStockForm,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		pProduct: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {};
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		product: {
			get() {
				return this.pProduct;
			},
			set(val) {
				this.$emit("set-product", val);
			},
		},
	},
	created: function () {},

	methods: {
		setDialog() {
			this.input = false;
			this.$emit("handle-event");
		},
	},
};
</script>
<style scoped></style>
