<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="text-h5"> New Dropdown Option </v-card-title>
      <v-card-text>
        <v-text-field label="Name" v-model="displayName"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" rounded depressed @click="addDropdownOption">
          <i class="fas fa-plus mr-2"></i>Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import backendService from "@/services/backend-service";

export default {
  name: "DropdownOptionDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dropdownId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      displayName: "",
    };
  },
  created: function () {},
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    addDropdownOption() {
      let dropdownOption = {};
      if (this.dropdownId == -1) {
        this.dialog = false;
        return;
      }
      dropdownOption.displayName = this.displayName;
      dropdownOption.dropdownId = this.dropdownId;
      backendService.addDropdownOption(dropdownOption).then((response) => {
        dropdownOption.id = response.data.insertedId;
        this.$store.dispatch("getDropdownToOptions");
        this.dialog = false;
      });
    },
  },
};
</script>
<style scoped></style>
