<template>
	<v-container class="d-flex justify-center pa-0"
		><v-card elevation="0" class="grey lighten-3" width="50%">
			<v-card-text class="d-flex align-center justify-space-between">
				<h1>New Customer</h1>
				<v-btn
					v-if="isUpdating"
					color="primary"
					depressed
					rounded
					@click="updateCustomer"
					:loading="isLoading"
					><i class="fas fa-save mr-2"></i>Update Customer</v-btn
				>
				<v-btn
					v-else
					color="primary"
					depressed
					rounded
					@click="addCustomer"
					:loading="isLoading"
					:disabled="!isFormValid"
					><i class="fas fa-user-plus mr-2"></i>Add New
					Customer</v-btn
				>
			</v-card-text>
			<v-card-text>
				<v-container class="white rounded-xl">
					<v-form ref="form" v-model="isFormValid">
						<v-text-field
							:rules="nameRules"
							label="Full Name"
							v-model="customer.name"
							dense
							rounded
							outlined
							required></v-text-field>
						<v-text-field
							:rules="textRules"
							label="Address 1"
							dense
							rounded
							outlined
							v-model="customer.address1"
							required></v-text-field>
						<v-text-field
							:rules="textRules"
							label="Address 2"
							dense
							rounded
							outlined
							v-model="customer.address2"
							required></v-text-field>
						<v-text-field
							label="Email"
							v-model="customer.email"
							dense
							rounded
							outlined></v-text-field>
						<v-text-field
							:rules="phoneRules"
							label="Phone"
							v-model="customer.phone"
							dense
							rounded
							outlined
							required></v-text-field>
					</v-form>
				</v-container>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import eventBus from "@/js/event-bus";
import generalMixin from "@/js/general-mixin";
import formMixin from "@/js/form-mixin";

export default {
	name: "CustomerForm",
	mixins: [generalMixin, formMixin],
	mounted: function () {
		if (this.$route.params.id) {
			this.getUpdateForm();
		}
	},
	data() {
		return {
			isLoading: false,
			isUpdating: false,
			customer: {},
		};
	},

	computed: {},
	methods: {
		addCustomer() {
			this.isLoading = true;
			backendService
				.addCustomer(this.customer)
				.then((response) => {
					this.customer.id = response.data;
					this.timer(1000).then(() => {
						this.$store.commit("addCustomer", this.customer);
						this.isLoading = false;
						this.customer = {};
						this.$refs.form.reset();
						eventBus.$emit(
							"setSnackbar",
							"Successfully added a new customer",
							"success",
							true
						);
					});
				})
				.catch(() => {
					this.timer(1000).then(() => {
						this.isLoading = false;
						eventBus.$emit(
							"setSnackbar",
							"Ops! Something is not right",
							"error",
							true
						);
					});
				});
		},
		getUpdateForm() {
			this.isUpdating = true;
			this.getCustomerById(this.$route.params.id);
		},
		getCustomerById(id) {
			backendService.getCustomerById(id).then((response) => {
				this.customer = response.data;
			});
		},
		updateCustomer() {
			this.isLoading = true;
			backendService
				.updateCustomer(this.customer)
				.then(() => {
					this.$store.commit("updateCustomer", this.customer);
					this.timer(1000).then(() => {
						this.isLoading = false;

						eventBus.$emit(
							"setSnackbar",
							"Successfully updated customer information",
							"success",
							true
						);
					});
				})
				.catch(() => {
					this.isLoading = false;
					eventBus.$emit(
						"setSnackbar",
						"Ops! Something is not right!",
						"error",
						true
					);
				});
		},
	},
};
</script>
