var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"grey lighten-3"},[_c('v-card-text',[_c('h1',[_vm._v("Your Orders")])]),(_vm.orders.length < 1)?_c('v-card-text',{staticClass:"grey lighten-2 rounded-xl text-center d-flex flex-column"},[_c('h2',[_vm._v("Nothing to See")]),_c('i',{staticClass:"fas fa-spider"})]):_vm._e(),_c('v-card-text',{staticClass:"mb-2",attrs:{"elevation":"0"}},_vm._l((_vm.orders),function(order){return _c('v-container',{key:`order-${order.id}`,staticClass:"mb-2 pa-0 d-flex justify-space-between"},[_c('v-container',{staticClass:"white rounded-xl mr-2",attrs:{"fluid":""}},[_c('v-container',{staticClass:"d-flex justify-space-between"},[_c('v-container',[_vm._v(" Order # "),_c('span',[_c('br'),_c('h2',[_c('a',{on:{"click":function($event){return _vm.$router.push({
												name: 'Shipment.OrderDetail',
												params: { id: order.id },
											})}}},[_vm._v(" "+_vm._s(order.id)+" ")])])])]),_c('v-container',[_vm._v(" Order Placed "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.convertToDate(order.dateCreated))+" ")])]),_c('v-container',[_vm._v(" Total "),_c('span',[_c('br'),_vm._v(" $ "+_vm._s(_vm.calculateTotal( order.subtotal, order.deliveryFee ).toFixed(2))+" ")])]),_c('v-container',[_vm._v(" Invoice # "),(order.invoiceId)?_c('span',[_c('br'),_c('a',{on:{"click":function($event){return _vm.$router.push({
											name: 'InvoiceUpdate',
											params: { id: order.invoiceId },
										})}}},[_vm._v(_vm._s(order.invoiceId))])]):_c('span',[_c('br'),_vm._v(" N/A ")])])],1),_c('v-divider'),_c('v-container',_vm._l((order.orderDetails),function(orderDetail){return _c('v-container',{key:`order-detail-${order.id}-${orderDetail.id}`,staticClass:"d-flex"},[_c('img',{staticClass:"image-preview",attrs:{"src":_vm.getProductPictureUrlById(
										orderDetail.pictureId
									)}}),_c('v-container',{staticClass:"d-flex flex-column"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
											name: 'ProductDetail',
											params: {
												id: orderDetail.productId,
											},
										})}}},[_vm._v(" "+_vm._s(orderDetail.productTitle)+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" $ "+_vm._s(orderDetail.unitPrice.toFixed(2))+" ")]),_c('span',[_vm._v("Qty: "+_vm._s(orderDetail.quantity))])])],1)}),1)],1),_c('v-container',{staticClass:"w-25 white rounded-xl d-flex flex-column",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","rounded":"","depressed":"","disabled":!!order.invoiceId && order.invoiceId != 0,"loading":order.id == _vm.selectedId},on:{"click":function($event){return _vm.generateInvoice(order)}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v("Generate Invoice")]),_c('v-btn',{attrs:{"color":"error","rounded":"","depressed":"","loading":order.id == _vm.toDeleteId,"disabled":!!order.invoiceId},on:{"click":function($event){return _vm.deleteOrder(order)}}},[_c('i',{staticClass:"fa-solid fa-trash-can mr-2"}),_vm._v("Delete Order")])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }