var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shipments,"items-per-page":15},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_c('a',{on:{"click":function($event){return _vm.$router.push({
								name: 'ShipmentUpdate',
								params: { id: item.id },
							})}}},[_vm._v(_vm._s(item.id))])]),_c('td',[_vm._v(_vm._s(item.mtlTracking))]),_c('td',[_vm._v(_vm._s(_vm.getCustomerName(item.senderId)))]),_c('td',[_vm._v(_vm._s(_vm.getCustomerName(item.receiverId)))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.totalBox)+"/"+_vm._s(item.totalWeight))]),_c('td',[_vm._v(_vm._s(_vm.convertToDate(item.shipmentDate)))]),_c('td',[_vm._v(_vm._s(_vm.getOptionName(item.shipmentStatusId)))]),_c('td',[(!item.invoiceId)?_c('span',[_vm._v("N/A")]):_vm._e(),(item.invoiceId)?_c('a',{on:{"click":function($event){return _vm.$router.push({
								name: 'InvoiceUpdate',
								params: { id: item.id },
							})}}},[_vm._v(_vm._s(item.invoiceId))]):_vm._e()])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }