<template>
	<v-container fluid>
		<v-card elevation="0" class="grey lighten-3">
			<v-card-text>
				<h1>Order Details</h1>
			</v-card-text>
			<v-card-text>
				<v-container class="white rounded-xl">
					<v-container class="d-flex justify-space-between">
						<v-container>
							Order #
							<span>
								<br />
								{{ order.id }}
							</span>
						</v-container>
						<v-container>
							Order Placed
							<span>
								<br />
								{{ convertToDate(order.dateCreated) }}
							</span>
						</v-container>

						<v-container>
							Order Placed
							<span>
								<br />
								{{ convertToDate(order.dateCreated) }}
							</span>
						</v-container>
						<v-container>
							Total
							<span>
								<br />
								{{
									convertToDecimal(
										order.subtotal + order.deliveryFee
									)
								}}
							</span>
						</v-container>
						<v-container
							>Invoice #<span v-if="order.invoiceId"
								><br /><a
									@click="
										$router.push({
											name: 'InvoiceUpdate',
											params: { id: order.invoiceId },
										})
									"
									>{{ order.invoiceId }}</a
								></span
							>
							<span v-else><br />N/A</span>
						</v-container>
					</v-container>

					<v-container>
						<v-container
							v-for="orderDetail in order.orderDetails"
							:key="`order-detail-${orderDetail.id}`"
							class="d-flex"
							fluid>
							<img
								class="image-preview mr-2"
								:src="
									getProductPictureUrlById(
										orderDetail.pictureId
									)
								" />

							<v-container fluid class="d-flex flex-column">
								<a
									@click="
										$router.push({
											name: 'ProductDetail',
											params: {
												id: orderDetail.productId,
											},
										})
									">
									{{ orderDetail.productTitle }}
								</a>
								<span class="red--text">
									$
									{{
										convertToDecimal(orderDetail.unitPrice)
									}}
								</span>
								<span>Qty: {{ orderDetail.quantity }}</span>
							</v-container>
						</v-container>
					</v-container>
				</v-container>
			</v-card-text>
		</v-card>
		<!-- <v-row
      v-for="orderDetail in order.orderDetails"
      :key="`orderDetail-${orderDetail.id}`">
      <v-col cols="10">{{ orderDetail.productTitle }}</v-col>
      <v-col cols="1">{{ orderDetail.quantity }}</v-col>
      <v-col cols="1">{{ orderDetail.unitPrice }}</v-col>
    </v-row> -->
	</v-container>
</template>
<script>
import backendService from "../services/backend-service.js";
import generalMixin from "@/js/general-mixin";
export default {
	name: "OrderDetail",
	data() {
		return {
			order: {},
		};
	},
	mixins: [generalMixin],
	created: function () {
		this.getOrder();
	},
	computed: {},
	methods: {
		getProductPictureUrlById(imageId) {
			return backendService.getProductPictureUrlById(imageId);
		},
		getOrder() {
			backendService
				.getOrderById(this.$route.params.id)
				.then((response) => {
					this.order = response.data;
				});
		},
		print() {
			this.printReceipt(this.order);
		},
	},
};
</script>
<style scoped>
.image-preview {
	object-fit: contain;
	image-rendering: smooth;
	width: 100px;
	height: 100px;
}
</style>
