<template>
	<v-dialog v-model="input" width="50%">
		<v-container
			class="white rounded-xl d-flex align-center justify-space-around"
			style="height: 400px">
			<v-btn
				class="rounded-xl"
				color="primary"
				x-large
				style="font-size: 30px; height: 300px; width: 300px"
				@click="emitEvent(cash)"
				><i class="fas fa-money-bill mr-2"></i>Cash</v-btn
			>
			<v-btn
				class="rounded-xl"
				color="primary"
				x-large
				style="font-size: 30px; height: 300px; width: 300px"
				@click="emitEvent(digital)"
				>Debit/Credit</v-btn
			>
		</v-container>
		<!-- <v-card light class="rounded-xl py-5">
			<v-card-text>
				<v-autocomplete
					rounded
					outlined
					label="Payment Type"
					:items="paymentTypes"
					item-value="id"
					item-text="displayName"
					v-model="paymentTypeId"></v-autocomplete>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn rounded depressed color="primary" @click="emitEvent"
					>Okay</v-btn
				>
			</v-card-actions>
		</v-card> -->
	</v-dialog>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import { PaymentType } from "@/js/enum.js";
export default {
	name: "PaymentDialog",
	mixins: [generalMixin],

	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		paymentTypes() {
			if (this.$store.getters["dropdownToOptions"]["paymentType"]) {
				return this.$store.getters["dropdownToOptions"]["paymentType"]
					.options;
			}
			return [];
		},

		cash() {
			return this.getIdByEnum(PaymentType.Cash);
		},
		digital() {
			return this.getIdByEnum(PaymentType.Aba);
		},
	},
	data() {
		return { paymentTypeId: 0 };
	},
	methods: {
		emitEvent(paymentType) {
			PaymentType;
			this.input = false;
			this.$emit("handle-event", paymentType);
		},
	},
};
</script>
