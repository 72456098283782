<template>
	<div>
		<v-app-bar
			color="primary darken-1"
			dark
			elevation="0"
			dense
			outlined
			v-if="$route.name != 'Shipment.PrinterFriendly'">
			<v-container class="d-flex justify-space-around">
				<v-btn
					:class="{
						primary: routeName != 'NewShipment',
						warning: routeName == 'NewShipment',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'NewShipment' }).catch(() => {})
					"
					><i class="fas fa-plus mr-2"></i> New Shipment
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'ShipmentList',
						warning: routeName == 'ShipmentList',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'ShipmentList' }).catch(() => {})
					"
					><i class="fas fa-cubes mr-2"></i> Shipment List
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'NewInvoice',
						warning: routeName == 'NewInvoice',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'NewInvoice' }).catch(() => {})
					"
					><i class="fas fa-plus mr-2"></i> New Invoice
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'InvoiceList',
						warning: routeName == 'InvoiceList',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'InvoiceList' }).catch(() => {})
					"
					><i class="fas fa-file-invoice mr-2"></i> Invoice List
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'NewShipmentGroup',
						warning: routeName == 'NewShipmentGroup',
					}"
					depressed
					rounded
					@click="
						$router
							.push({ name: 'NewShipmentGroup' })
							.catch(() => {})
					">
					<i class="fas fa-plus mr-2"></i>New Shipment Group
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'ShipmentGroupList',
						warning: routeName == 'ShipmentGroupList',
					}"
					depressed
					rounded
					@click="
						$router
							.push({ name: 'ShipmentGroupList' })
							.catch(() => {})
					"
					><i class="fas fa-plane-departure mr-2"></i> Shipment Group
					List
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'NewCustomer',
						warning: routeName == 'NewCustomer',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'NewCustomer' }).catch(() => {})
					">
					<i class="fas fa-user-plus mr-2"></i>New Customer
				</v-btn>
				<v-btn
					:class="{
						primary: routeName != 'CustomerList',
						warning: routeName == 'CustomerList',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'CustomerList' }).catch(() => {})
					"
					><i class="fas fa-users mr-2"></i>
					Customer List
				</v-btn>
			</v-container>
		</v-app-bar>
		<v-app-bar
			color="primary darken-1"
			dark
			elevation="0"
			dense
			outlined
			v-if="$route.name != 'Shipment.PrinterFriendly'">
			<v-container class="d-flex justify-center">
				<v-btn
					class="mx-2"
					:class="{
						primary: routeName != 'NewProduct',
						warning: routeName == 'NewProduct',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'NewProduct' }).catch(() => {})
					"
					><i class="fas fa-plus mr-2"></i> New Product
				</v-btn>
				<v-btn
					class="mx-2"
					:class="{
						primary: routeName != 'Shipment.StorePage',
						warning: routeName == 'Shipment.StorePage',
					}"
					depressed
					rounded
					@click="
						$router
							.push({
								name: 'Shipment.StorePage',
							})
							.catch(() => {})
					"
					><i class="fas fa-store mr-2"></i> Store Page
				</v-btn>
				<v-btn
					class="mx-2"
					:class="{
						primary: routeName != 'Shipment.Cart',
						warning: routeName == 'Shipment.Cart',
					}"
					depressed
					rounded
					@click="
						$router.push({ name: 'Shipment.Cart' }).catch(() => {})
					"
					><i class="fas fa-shopping-cart mr-2"></i> Cart ({{
						cartCount
					}})
				</v-btn>
				<v-btn
					class="mx-2"
					:class="{
						primary: routeName != 'Shipment.OrderList',
						warning: routeName == 'Shipment.OrderList',
					}"
					depressed
					rounded
					@click="
						$router
							.push({ name: 'Shipment.OrderList' })
							.catch(() => {})
					"
					><i class="fas fa-th-large mr-2"></i> Order List
				</v-btn>
				<v-btn
					class="mx-2"
					:class="{
						primary: routeName != 'Shipment.ContactUs',
						warning: routeName == 'Shipment.ContactUs',
					}"
					depressed
					rounded
					@click="
						$router
							.push({ name: 'Shipment.ContactUs' })
							.catch(() => {})
					"
					><i class="fa-regular fa-address-book mr-2"></i>Contact Us
				</v-btn>
			</v-container>
		</v-app-bar>
	</div>
</template>
<script>
//import bus from "@/bus/bus.js";
import generalMixin from "@/js/general-mixin.js";
import backendService from "@/services/backend-service";
//import backendService from "../services/backend-service";
export default {
	name: "ShipmentManagmentNavbar",
	// created: function() {
	//     this.$store.dispatch('getAllDropdownOptions');
	// }
	mixins: [generalMixin],
	computed: {
		cartCount() {
			return this.$store.getters["cartCount"];
		},
		categories() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["category"]
			)
				return this.$store.getters["dropdownToOptions"]["category"]
					.options;
			return [];
		},
		allDropdownOptions() {
			if (this.$store.getters["allDropdownOptions"])
				return this.$store.getters["allDropdownOptions"];
			return [];
		},

		accountLabel() {
			if (this.isLoggedIn) {
				return this.currentUsername;
			}
			return "Account";
		},
		routeName() {
			return this.$route.name;
		},
	},
	data() {
		return {
			drawer: false,
		};
	},
	created: function () {},
	methods: {
		closeNav() {
			document.getElementById("mySidenav").style.width = "0";
			this.showContainer = false;
		},
		openNav() {
			document.getElementById("mySidenav").style.width = "250px";
		},
		showDropdown(dropdown) {
			this.isUncollapsed[dropdown] = !this.isUncollapsed[dropdown];
			// let allElements = document.getElementsByClassName('dropdown-container');
			// console.log(allElements);

			// allElements.forEach(ele => {
			//     ele.style.display = "block";
			// })
		},
		logout() {
			backendService;
			this.$router.push({ name: "Logout" }).catch(() => {});
			// backendService.logout().then(() => {
			//   console.log("session logout");
			// });
		},
	},
};
</script>
<style scoped>
nav .btn {
	color: white;
}
.sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	background-color: #3473d9;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 60px;
}

.sidenav a,
.dropdown-btn {
	padding: 6px 8px 6px 32px;
	text-decoration: none;
	font-size: 20px;
	color: white;
	display: block;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	outline: none;
}

.sidenav a:hover {
	color: #aaaaaa;
}

.sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
}
.dropdown-container {
	background-color: #516894;
	padding-left: 8px;
}
#categories-dropdown a:first-child {
	font-weight: bold;
}
</style>
