<template>
	<v-container
		fluid
		class="d-flex justify-space-around align-center my-5 white rounded-xl w-75">
		<v-card elevation="0" v-if="!isMobile">
			<img src="@/assets/73-authentication.png" />
		</v-card>
		<v-card elevation="0">
			<v-card-title>User Registration</v-card-title>
			<v-card-text v-if="!isSuccessful">
				<v-form ref="form" v-model="isFormValid">
					<v-row>
						<v-col>
							<v-text-field
								:rules="nameRules"
								v-model="first"
								label="First Name"
								required></v-text-field>
						</v-col>
						<v-col>
							<v-text-field
								:rules="nameRules"
								v-model="last"
								label="Last Name"
								required></v-text-field>
						</v-col>
					</v-row>

					<v-text-field
						:rules="emailRules"
						v-model="email"
						label="Email"
						required></v-text-field>
					<v-text-field
						v-model="phone"
						label="Phone"
						required></v-text-field>
					<v-text-field
						:rules="passRules"
						type="password"
						v-model="password"
						label="Password"
						required></v-text-field>
					<v-text-field
						:rules="passRules"
						type="password"
						v-model="confPassword"
						label="Confirm Password"
						required></v-text-field>
				</v-form>
			</v-card-text>
			<v-card-text class="text-center" v-else>
				<h1 class="mb-5 blue--text">You are almost done</h1>

				<p>
					We have sent you an email with a verify link. Check the
					email to verify your email address.
				</p>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn depressed rounded color="warning" @click="clear">
					<i class="fas fa-times mr-2"></i>
					Clear
				</v-btn>
				<v-btn
					depressed
					rounded
					color="primary"
					@click="register"
					:disabled="!isFormValid"
					:loading="isLoading">
					<i class="far fa-save mr-2"></i>
					Register
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import generalMixin from "@/js/general-mixin";
import eventBus from "../js/event-bus.js";
import formMixin from "@/js/form-mixin.js";
export default {
	name: "UserRegister",
	mixins: [formMixin, generalMixin],
	data() {
		return {};
	},
	methods: {
		clear() {
			this.clearVariables();
			this.$refs.form.resetValidation();
		},
		register() {
			if (!this.$refs.form.validate()) {
				return;
			}
			let account = {};
			account.first = this.first;
			account.last = this.last;
			account.email = this.email;
			account.password = this.password;
			account.phone = this.phone;
			this.isLoading = true;
			backendService
				.registerUser(account)
				.then(() => {
					this.timer(1000).then(() => {
						eventBus.$emit(
							"setSnackbar",
							"User Successfully Register",
							"success",
							true
						);
						this.clear();
						this.isLoading = false;
						this.isSuccessful = true;
					});
				})
				.catch(() => {
					this.timer(1000).then(() => {
						eventBus.$emit(
							"setSnackbar",
							"Ops! Something is not right!",
							"red",
							true
						);
						this.isLoading = false;
					});
				});
		},
	},
};
</script>
<style scoped></style>
