var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"grey lighten-3",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('h1',[_vm._v("Order Details")])]),_c('v-card-text',[_c('v-container',{staticClass:"white rounded-xl"},[_c('v-container',{staticClass:"d-flex justify-space-between"},[_c('v-container',[_vm._v(" Order # "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.order.id)+" ")])]),_c('v-container',[_vm._v(" Order Placed "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.convertToDate(_vm.order.dateCreated))+" ")])]),_c('v-container',[_vm._v(" Order Placed "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.convertToDate(_vm.order.dateCreated))+" ")])]),_c('v-container',[_vm._v(" Total "),_c('span',[_c('br'),_vm._v(" "+_vm._s(_vm.convertToDecimal( _vm.order.subtotal + _vm.order.deliveryFee ))+" ")])]),_c('v-container',[_vm._v("Invoice #"),(_vm.order.invoiceId)?_c('span',[_c('br'),_c('a',{on:{"click":function($event){return _vm.$router.push({
											name: 'InvoiceUpdate',
											params: { id: _vm.order.invoiceId },
										})}}},[_vm._v(_vm._s(_vm.order.invoiceId))])]):_c('span',[_c('br'),_vm._v("N/A")])])],1),_c('v-container',_vm._l((_vm.order.orderDetails),function(orderDetail){return _c('v-container',{key:`order-detail-${orderDetail.id}`,staticClass:"d-flex",attrs:{"fluid":""}},[_c('img',{staticClass:"image-preview mr-2",attrs:{"src":_vm.getProductPictureUrlById(
										orderDetail.pictureId
									)}}),_c('v-container',{staticClass:"d-flex flex-column",attrs:{"fluid":""}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
											name: 'ProductDetail',
											params: {
												id: orderDetail.productId,
											},
										})}}},[_vm._v(" "+_vm._s(orderDetail.productTitle)+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" $ "+_vm._s(_vm.convertToDecimal(orderDetail.unitPrice))+" ")]),_c('span',[_vm._v("Qty: "+_vm._s(orderDetail.quantity))])])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }