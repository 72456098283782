<template>
	<v-container fluid class="pa-0" style="background-color: rgba(0, 0, 0, 0)">
	</v-container>
</template>

<script>
import backendService from "@/services/backend-service";
export default {
	name: "HomePage",
	components: {
		// "carousel-slide": CarouselSlide,
		// "category-list": CategoryList,
		// "product-card-list": ProductCardList,
	},
	mounted: function () {
		if (this.$router.params.id) {
			this.trackingNumber = this.$router.params.id;
			this.getShipmentStatus();
		}
	},
	data() {
		return {
			trackingNumber: 0,
		};
	},
	computed: {
		background() {
			return require("@/assets/harbor.jpg");
		},
	},
	methods: {
		getShipmentStatus() {
			backendService
				.getShipmentStatusById(this.trackingNumber)
				.then(() => {});
		},
	},
};
</script>
