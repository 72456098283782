<template>
  <v-container
    fluid
    class="d-flex justify-space-around align-center white mt-5 w-75 rounded-xl">
    <v-card elevation="0">
      <img width="500" height="500" src="@/assets/5-designer.png" />
      <v-card-text class="text-center">
        <h3>នេះជាជំហ៊ានតំបូងទៅរកអជីវកម្មនិងភាពជោគជ័យរបស់អ្នក</h3>
        <h3>This is a first step to your entrepreneurship and success</h3>
      </v-card-text>
    </v-card>
    <v-card elevation="0" min-width="350" width="60%">
      <v-card-title>Store Registration</v-card-title>
      <v-card-text v-if="!isSuccessful">
        <v-form ref="form" v-model="isFormValid">
          <v-text-field
            :rules="nameRules"
            v-model="name"
            label="ឈ្មោះហាងរបស់អ្នក - Store Name"
            required></v-text-field>
          <v-textarea
            :rules="nameRules"
            v-model="description"
            label="ពណ៍នាហាងរបស់អ្នក - Store Description"
            required></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-text class="text-center" v-else>
        <h1 class="mb-5 blue--text">Congratulations!</h1>

        <p>
          Your store has been successfully registered. You can start adding
          items to your inventory.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn depressed rounded color="warning" @click="clear">
          <i class="fas fa-times mr-2"></i>Clear
        </v-btn>
        <v-btn
          depressed
          rounded
          color="primary"
          @click="register"
          :disabled="!isFormValid"
          :loading="isLoading">
          <i class="far fa-save mr-2"></i>Register
        </v-btn>
        <v-btn
          depressed
          rounded
          color="primary"
          v-if="isSuccessful"
          @click="$router.push({ name: 'ProductForm' })"
          ><i class="fas fa-warehouse mr-2"></i>Add Product</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import eventBus from "../js/event-bus.js";
import formMixin from "@/js/form-mixin.js";
import generalMixin from "@/js/general-mixin";
export default {
  name: "UserRegister",
  mixins: [formMixin, generalMixin],
  data() {
    return {};
  },
  methods: {
    clear() {
      this.clearVariables();
      this.$refs.form.resetValidation();
    },
    register() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let store = {};
      store.name = this.name;
      store.description = this.description;

      this.isLoading = true;
      backendService
        .registerStore(store)
        .then(() => {
          this.timer(1000).then(() => {
            eventBus.$emit(
              "setSnackbar",
              "User Successfully Register",
              "success",
              true
            );
            this.clear();
            this.isLoading = false;
            this.isSuccessful = true;
          });
        })
        .catch(() => {
          this.timer(1000).then(() => {
            eventBus.$emit(
              "setSnackbar",
              "Ops! Something is not right!",
              "red",
              true
            );
            this.isLoading = false;
          });
        });
    },
  },
};
</script>
<style scoped></style>
