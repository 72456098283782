<template>
	<v-container class="text-center">
		<h1>404 Page Not Found</h1>
	</v-container>
</template>
<script>
export default {
	name: "PageCode404",
};
</script>
