<template>
	<v-container class="white rounded-xl">
		<v-container class="d-flex justify-space-between">
			<h1>Images</h1>
			<v-btn
				rounded
				depressed
				@click="$refs.fileDialog.click()"
				color="primary">
				<i class="fas fa-plus"></i>
			</v-btn>
		</v-container>
		<v-container class="d-flex">
			<v-slide-group multiple show-arrows>
				<v-slide-item
					v-for="(img, idx) in existingImages"
					:key="`img-${idx}`"
					v-slot="{}">
					<v-card elevation="0">
						<v-card-text class="d-flex justify-center align-center">
							<img
								class="image-preview"
								:src="getProductPictureUrlById(img.id)" />
						</v-card-text>
						<v-card-actions
							class="d-flex justify-center align-center">
							<v-btn
								color="error"
								depressed
								rounded
								small
								@click="
									deleteDialog = true;
									toDelete = idx;
								">
								<i class="fas fa-trash-alt"></i>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-slide-item>
				<v-slide-item
					v-for="(img, idx) in imageUrls"
					:key="`img-url-${idx}`">
					<v-card elevation="0">
						<v-card-text class="d-flex justify-center align-center">
							<img class="image-preview" :src="img" />
						</v-card-text>
						<v-card-actions
							class="d-flex justify-center align-center">
							<v-btn
								color="error"
								depressed
								rounded
								@click="
									removeDialog = true;
									toRemove = idx;
								">
								<i class="fas fa-times"></i>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-slide-item>
			</v-slide-group>
		</v-container>
		<input
			ref="fileDialog"
			type="file"
			accept="image/png, image/jpeg, image/jpg"
			@change="handleFileChange"
			hidden
			multiple />
		<general-dialog
			:dialog="removeDialog"
			:options="removeOptions"
			v-model="removeDialog"
			@handle-event="removeImage"></general-dialog>
		<general-dialog
			:dialog="deleteDialog"
			:options="deleteOptions"
			v-model="deleteDialog"
			@handle-event="deleteProductPicture"></general-dialog>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import GeneralDialog from "./GeneralDialog.vue";
export default {
	name: "image-form",
	components: { "general-dialog": GeneralDialog },
	props: {
		pExistingImages: {
			type: Array,
			default: () => {
				return [];
			},
		},
		pNewImages: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {
			deleteDialog: false,
			removeDialog: false,
			imageUrls: [],
			imageNames: [],
			toRemove: -1,
			toDelete: -1,
			deleteOptions: {
				btnText: "Yes",
				message: "This action is not revertable!",
				title: "Are you sure you want to delete the image?",
			},
			removeOptions: {
				btnText: "Yes",
				message: "",
				title: "Are you sure you want to remove the image?",
			},
		};
	},
	computed: {
		existingImages: {
			get() {
				return this.pExistingImages;
			},
			set(val) {
				this.$emit("existingImages", val);
			},
		},
		newImages: {
			get() {
				return this.pNewImages;
			},
			set(val) {
				this.$emit("newImages", val);
			},
		},
	},
	methods: {
		deleteProductPicture() {
			backendService
				.deleteProductPictureById(this.existingImages[this.toDelete].id)
				.then(() => {
					this.existingImages.splice(this.toDelete, 1);
					this.toDelete = -1;
				});
		},
		getProductPictureUrlById(imageId) {
			return backendService.getProductPictureUrlById(imageId);
		},
		handleFileChange(e) {
			let filterFiles = [];
			for (let i = 0; i < e.target.files.length; i++) {
				let file = e.target.files[i];
				if (!this.imageNames.includes(file.name))
					filterFiles.push(file);
			}
			this.$refs.fileDialog.type = "text";
			this.$refs.fileDialog.type = "file";
			filterFiles.forEach((file) => {
				let url = URL.createObjectURL(file);
				this.imageUrls.push(url);
				this.imageNames.push(file.name);
				this.newImages.push(file);
			});
		},
		removeImage() {
			this.imageNames.splice(this.toRemove, 1);
			this.imageUrls.splice(this.toRemove, 1);
			this.newImages.splice(this.toRemove, 1);
		},
		resetForm() {
			this.imageNames = [];
			this.imageUrls = [];
		},
	},
};
</script>
<style scoped>
.check-main-btn {
	width: 80px;
	height: 30px;
}
.remove-image-btn {
	width: 80px;
	height: 30px;
}
.image-preview {
	object-fit: contain;
	image-rendering: smooth;
	width: 60px;
	height: 60px;
}
.jumbo-btn {
	width: 80px;
	height: 80px;
	border: 2px dashed gray;
	margin-right: 10px;
	float: left;
}
.jumbo-btn:hover {
	opacity: 0.7;
}
.section {
	width: 100%;
	border-bottom: 1px solid #dedede;
	margin-bottom: 2%;
}
</style>
