<template>
	<v-container fluid class="pa-0">
		<v-card class="grey lighten-3">
			<v-card-text>
				<h1>Your Orders</h1>
			</v-card-text>

			<v-card-text
				v-if="orders.length < 1"
				class="grey lighten-2 rounded-xl text-center d-flex flex-column"
				><h2>Nothing to See</h2>
				<i class="fas fa-spider"></i
			></v-card-text>
			<v-card-text class="mb-2" elevation="0">
				<v-container
					v-for="order in orders"
					:key="`order-${order.id}`"
					class="mb-2 pa-0 d-flex justify-space-between">
					<v-container fluid class="white rounded-xl mr-2">
						<v-container class="d-flex justify-space-between">
							<v-container>
								Order #
								<span>
									<br />
									<h2>
										<a
											@click="
												$router.push({
													name: 'Shipment.OrderDetail',
													params: { id: order.id },
												})
											">
											{{ order.id }}
										</a>
									</h2>
								</span>
							</v-container>

							<v-container>
								Order Placed
								<span>
									<br />
									{{ convertToDate(order.dateCreated) }}
								</span>
							</v-container>
							<v-container>
								Total
								<span>
									<br />
									$
									{{
										calculateTotal(
											order.subtotal,
											order.deliveryFee
										).toFixed(2)
									}}
								</span>
							</v-container>
							<v-container>
								Invoice #
								<span v-if="order.invoiceId">
									<br />
									<a
										@click="
											$router.push({
												name: 'InvoiceUpdate',
												params: { id: order.invoiceId },
											})
										"
										>{{ order.invoiceId }}</a
									>
								</span>
								<span v-else
									><br />
									N/A
								</span>
							</v-container>
						</v-container>
						<v-divider></v-divider>
						<v-container>
							<v-container
								v-for="orderDetail in order.orderDetails"
								:key="`order-detail-${order.id}-${orderDetail.id}`"
								class="d-flex">
								<img
									class="image-preview"
									:src="
										getProductPictureUrlById(
											orderDetail.pictureId
										)
									" />
								<v-container class="d-flex flex-column">
									<a
										@click="
											$router.push({
												name: 'ProductDetail',
												params: {
													id: orderDetail.productId,
												},
											})
										">
										{{ orderDetail.productTitle }}
									</a>
									<span class="red--text">
										$
										{{ orderDetail.unitPrice.toFixed(2) }}
									</span>
									<span>Qty: {{ orderDetail.quantity }}</span>
								</v-container>
							</v-container>
						</v-container>
					</v-container>
					<v-container
						fluid
						class="w-25 white rounded-xl d-flex flex-column">
						<v-btn
							class="mb-2"
							color="warning"
							rounded
							depressed
							@click="generateInvoice(order)"
							:disabled="
								!!order.invoiceId && order.invoiceId != 0
							"
							:loading="order.id == selectedId"
							><i class="fas fa-plus mr-2"></i>Generate
							Invoice</v-btn
						>
						<v-btn
							color="error"
							rounded
							depressed
							@click="deleteOrder(order)"
							:loading="order.id == toDeleteId"
							:disabled="!!order.invoiceId"
							><i class="fa-solid fa-trash-can mr-2"></i>Delete
							Order</v-btn
						>
					</v-container>
				</v-container>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import generalMixin from "@/js/general-mixin";
import Decimal from "decimal.js";
import { InvoiceType } from "@/js/enum";
export default {
	name: "OrderList",
	mixins: [generalMixin],
	data() {
		return {
			orders: [],
			toDeleteId: -1,
			isGenerateLoading: false,
			selectedId: -1,
		};
	},
	created: function () {
		this.getOrders();
	},
	methods: {
		calculateTotal(subtotal, deliveryFee) {
			return new Decimal(subtotal).plus(new Decimal(deliveryFee));
		},
		deleteOrder(order) {
			this.toDeleteId = order.id;
			backendService.deleteOrderById(order.id).then(() => {
				this.timer(1000).then(() => {
					this.orders = this.orders.filter((x) => x.id != order.id);
					this.toDeleteId = -1;
				});
			});
		},
		generateInvoice(order) {
			this.selectedId = order.id;
			this.timer(1000).then(() => {
				this.selectedId = order.id;
				this.$router.push({
					name: "NewInvoice",
					params: {
						order: order,
						isGenerating: true,
						type: this.getIdByEnum(InvoiceType.Order),
					},
				});
			});
		},
		getProductPictureUrlById(imageId) {
			return backendService.getProductPictureUrlById(imageId);
		},
		getOrders() {
			backendService.getOrders().then((response) => {
				this.orders = response.data;
			});
		},
	},
};
</script>
<style scoped>
.image-preview {
	object-fit: contain;
	image-rendering: smooth;
	width: 100px;
	height: 100px;
}
</style>
