<template>
	<v-card elevation="0" outlined min-width="350">
		<v-card-title>Product Detail</v-card-title>
		<v-card-text>
			<div class="d-flex justify-center">
				<!-- <h3 class="red--text hot-blink" style="position: absolute; right: 2px">
          Popular
        </h3> -->
				<img
					class="image-preview"
					:src="getProductPictureUrlById(product.pictureId)" />
			</div>
			<v-form ref="form" v-model="formValid" lazy-validation>
				<div class="d-flex justify-space-between">
					<v-text-field
						v-model.number="stockInfo.stockPrice"
						label="Stock Price"></v-text-field>
					<v-text-field
						class="ml-5"
						v-model.number="stockInfo.quantity"
						label="Quantity"></v-text-field>
				</div>

				<v-autocomplete
					:items="suppliers"
					item-text="displayName"
					item-value="id"
					v-model="stockInfo.supplierId"
					label="Supplier"></v-autocomplete>
				<v-date-picker
					v-model="stockInfo.datePurchased"></v-date-picker>
			</v-form>
		</v-card-text>
		<v-card-actions class="d-flex justify-end">
			<v-btn
				class="grey lighten-2 brown--text"
				depressed
				rounded
				@click="addStock"
				:loading="isLoading"
				>Confirm</v-btn
			></v-card-actions
		>
	</v-card>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
export default {
	name: "InStockForm",
	mixins: [generalMixin],
	components: {},
	props: {
		pProduct: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return { stockInfo: {}, formValid: false, isLoading: false };
	},
	computed: {
		suppliers() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["supplier"]
			) {
				return this.$store.getters["dropdownToOptions"]["supplier"]
					.options;
			}
			return [];
		},
		product: {
			get() {
				return this.pProduct;
			},
			set(val) {
				this.$emit("set-product", val);
			},
		},
	},

	methods: {
		addStock() {
			this.isLoading = true;
			this.stockInfo.productId = this.product.id;
			backendService.addStock(this.stockInfo).then((response) => {
				this.product.quantity = response.data;
				this.timer(1000).then(() => {
					this.isLoading = false;
					this.$emit("handle-event");
				});
			});
		},
		getProductPictureUrlById(imageId) {
			return backendService.getProductPictureUrlById(imageId);
		},
	},
};
</script>
<style scoped>
.image-preview {
	object-fit: contain;
	image-rendering: smooth;
	width: 150px;
	height: 150px;
}
</style>
