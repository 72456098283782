<template>
  <v-container fluid class="pa-0">
    <v-data-table
          :headers="headers"
          :items="customers"
          :items-per-page="15"
          class="elevation-1">
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <a
                  @click="
                    $router
                      .push({
                        name: 'CustomerUpdate',
                        params: { id: item.id },
                      })
                      .catch(() => {})
                  "
                  >{{ item.id }}</a
                >
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.address1 }}</td>
              <td>{{ item.address2 }}</td>
              <td>{{ item.phone }}</td>
            </tr>
          </template></v-data-table
        >
  </v-container>
</template>
<script>
import backendService from "@/services/backend-service";

export default {
  name: "CustomerList",
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        { text: "Name", value: "name" },
        { text: "Address 1", value: "address1" },
        { text: "Address 2", value: "address2" },
        { text: "Phone", value: "phone" },
      ],
      customer: {},
    };
  },
  computed: {
    customers: {
      get() {
        return this.$store.getters["customers"];
      },
      set(val) {
        this.$store.commit("customers", val);
      },
    },
  },
  methods: {
    addCustomer() {
      backendService.addCustomer(this.customer).then((response) => {
        this.customer.id = response.data;
        this.customers.push(this.customer);
        this.customer = {};
      });
    },
  },
};
</script>
