<template>
	<v-container fluid class="d-flex justify-space-between">
		<v-card class="grey lighten-3" elevation="0" width="80%">
			<v-card-text class="d-flex align-center justify-space-between">
				<h1>Shopping Cart</h1>
				<h2 class="mr-6">Price</h2>
			</v-card-text>
			<v-card-text class="d-flex flex-column" v-if="!isSuccessful">
				<v-container
					v-for="(item, key) in cart"
					:key="key"
					class="white rounded-xl d-flex justify-start mb-3">
					<div>
						<img
							class="image-preview"
							:src="
								getProductPictureUrlById(item.product.pictureId)
							" />
					</div>
					<v-container class="d-flex flex-column">
						<div class="d-flex justify-space-between mb-2">
							<h3 style="width: 80%" class="kh">
								{{ item.product.title }}
							</h3>
							<h3
								class="red--text"
								:class="[
									{
										'text-decoration-line-through':
											customPrices[item.product.id],
									},
								]">
								$
								{{
									lineTotal(
										item.product.unitPrice,
										1
									).toFixed(2)
								}}
							</h3>
						</div>
						<div class="d-flex">
							<div style="width: 30%" class="d-flex mr-2">
								<v-text-field
									rounded
									dense
									outlined
									label="Quantity"
									v-model.number="item.count"
									@change="
										updateItemCount(key)
									"></v-text-field>
								<v-btn
									class="mt-1 ml-4"
									@click="
										toRemove = key;
										removeDialog = true;
									"
									rounded
									depressed
									color="error">
									<i class="fas fa-times"></i>
								</v-btn>
							</div>
							<div class="d-flex">
								<v-text-field
									class="mr-2"
									label="Custom Price"
									rounded
									dense
									outlined
									v-show="!!customPrices[item.product.id]"
									v-model.number="
										customPrices[item.product.id]
									"
									@change="
										calculateSubtotal();
										calculateTotal();
									"></v-text-field>
								<v-btn
									v-if="!!customPrices[item.product.id]"
									class="mt-1 mr-2"
									color="warning"
									rounded
									depressed
									@click="
										customPrices[item.product.id] = null;
										$forceUpdate();
									"
									><i class="fa-solid fa-rotate-left"></i
								></v-btn>
								<v-btn
									v-else
									class="mt-1"
									color="warning"
									rounded
									depressed
									@click="
										customPrices[item.product.id] =
											item.product.unitPrice;
										$forceUpdate();
									"
									><i class="fa-solid fa-pencil mr-2"></i>
									Custom Price</v-btn
								>
							</div>
						</div>
					</v-container>
				</v-container>
				<div class="d-flex justify-end">
					<h2 class="red--text">$ {{ subtotal.toFixed(2) }}</h2>
				</div>
			</v-card-text>
			<v-card-text v-if="isSuccessful">
				<v-container class="d-flex justify-center"
					><div class="text-center">
						<h3 class="mb-5 green--text">
							អរគុណ! ពួកយេីងបានទទួលការកម្ម៉ង់របស់អ្នកហេីយ។
							<br />
							Thank you, your order has been placed.
						</h3>
						<h3 class="mb-5 green--text"></h3>
						<div>
							ពួកយេីងបានផ្ញេីរសារទៅអ្នកដែលភ្ជាប់ជាមួយលេខកម៉្មង់របស់អ្នក។
							លេខកម៉្មង់របស់អ្នកគឺ - {{ orderId }}
						</div>
						<img src="@/assets/29-happy.png" /></div
				></v-container>
			</v-card-text>
		</v-card>

		<v-card class="grey lighten-3" elevation="0" width="20%">
			<v-card-text>
				<v-container class="white rounded-xl">
					<div class="d-flex justify-space-between">
						<h4>Subtotal</h4>
						<h4 class="red--text">
							$
							{{ subtotal.toFixed(2) }}
						</h4>
					</div>
					<div class="d-flex justify-space-between">
						<h4>Delivery Fee</h4>
						<h4 class="red--text">
							$ {{ deliveryFee.toFixed(2) }}
						</h4>
					</div>
					<div class="d-flex justify-space-between">
						<h3>Total</h3>
						<h3 class="red--text">
							$
							{{ totalAmount.toFixed(2) }}
						</h3>
					</div>
				</v-container>
			</v-card-text>

			<v-card-text>
				<v-container class="d-flex justify-end">
					<v-btn
						color="warning"
						depressed
						rounded
						@click="submitOrder"
						:loading="isLoading"
						:disabled="!validSubmit">
						<i class="fas fa-paper-plane mr-2"></i>
						Submit Order
					</v-btn>
				</v-container>
			</v-card-text>
		</v-card>
		<general-dialog
			:dialog="removeDialog"
			:options="removeOptions"
			v-model="removeDialog"
			@handle-event="removeFromCart"></general-dialog>
	</v-container>
</template>
<script>
import backendService from "../services/backend-service.js";
import eventBus from "@/js/event-bus.js";
import GeneralDialog from "@/components/GeneralDialog.vue";
import generalMixin from "@/js/general-mixin";
import Decimal from "decimal.js";
export default {
	name: "ShoppingCart",
	mixins: [generalMixin],
	components: {
		"general-dialog": GeneralDialog,
	},
	data() {
		return {
			cashDialog: false,
			paymentType: {},
			deleteDialog: false,
			deleteOptions: {
				btnText: "Yes",
				message: "This action is not revertable!",
				title: "Are you sure you want to delete the info?",
			},
			deliveryInfoDialog: false,
			deliveryAddresses: [],
			isLoading: false,
			isSuccessful: false,
			payment: {
				amount: 0,
				referenceNumber: "",
			},
			order: {
				deliveryAddressId: 0,
				subtotal: 0,
				deliveryFee: 0,
			},
			orderId: null,
			toDelete: -1,
			toRemove: "",
			removeDialog: false,
			removeOptions: {
				btnText: "Yes",
				message: "",
				title: "Are you sure you want to remove the item?",
			},
			totalAmount: 0,
			customPrices: {},
			subtotal: 0,
		};
	},
	created: function () {
		this.calculateSubtotal();
		this.calculateTotal();
		this.getDeliveryAddresses();
	},
	computed: {
		cart: {
			get() {
				return this.$store.getters["cart"];
			},
			set(val) {
				this.$store.commit("setCart", val);
			},
		},
		cartCount: {
			get() {
				return this.$store.getters["cartCount"];
			},
			set(val) {
				this.$store.commit("setCartCount", val);
			},
		},

		deliveryFee() {
			// if (this.subtotal < 5000) {
			// 	return 200;
			// } else {
			// 	return 0;
			// }
			return 0;
		},
		paymentTypes() {
			if (this.$store.getters["dropdownToOptions"]["paymentType"]) {
				return this.$store.getters["dropdownToOptions"]["paymentType"]
					.options;
			}
			return [];
		},
		validDeliveryAddress() {
			if (this.order && this.order.deliveryAddressId) {
				return true;
			}
			return false;
		},
		validPayment() {
			if (this.paymentType && this.paymentType.strRef) {
				return true;
			}
			return false;
		},
		abaPayment() {
			if (this.paymentType && this.paymentType.strRef == "abaPay") {
				return true;
			}
			return false;
		},

		validSubmit() {
			return this.cartCount != 0;
		},
	},
	methods: {
		calculateSubtotal() {
			let sum = new Decimal(0);
			for (let key in this.cart) {
				let item = this.cart[key].product;
				let count = this.cart[key].count;
				let price = item.unitPrice;
				if (this.customPrices[item.id])
					price = this.customPrices[item.id];
				sum = sum.plus(this.lineTotal(price, count));
			}
			this.subtotal = sum;
		},
		calculateTotal() {
			this.totalAmount = new Decimal(this.subtotal).plus(
				new Decimal(this.deliveryFee)
			);
		},
		deleteDeliveryInfo() {
			let id = this.deliveryInfoLst[this.toDelete].id;
			backendService.deleteDeliveryInfoById(id).then(() => {
				this.deliveryInfoLst.splice(this.toDelete, 1);
			});
		},
		getProductPictureUrlById(imageId) {
			if (!imageId) return;
			return backendService.getProductPictureUrlById(imageId);
		},
		lineTotal(unitPrice, count) {
			return new Decimal(unitPrice).times(new Decimal(count));
		},
		getDeliveryAddresses() {
			let userId = this.currentUser.id;
			this.timer(500).then(() => {
				if (!userId) {
					this.getDeliveryAddresses();
				} else {
					this.getDeliveryAddressesByUserId(userId);
				}
			});
		},
		getDeliveryAddressesByUserId(userId) {
			backendService
				.getDeliveryAddressesByUserId(userId)
				.then((response) => {
					this.deliveryAddresses = response.data;
					for (let i = 0; i < this.deliveryAddresses.length; i++) {
						let deliveryAddress = this.deliveryAddresses[i];
						if (deliveryAddress.main) {
							this.order.deliveryAddressId = deliveryAddress.id;
						}
					}
				});
		},
		removeFromCart() {
			let cart = this.cart;
			let cartCount = this.cartCount - cart[this.toRemove].count;
			delete cart[this.toRemove];
			this.$store.commit("setCartCount", cartCount);
			this.$store.commit("setCart", cart);
			localStorage.setItem("cartCount", cartCount);
			localStorage.setItem("cart", JSON.stringify(cart));
		},
		setDeliveryInfoId(id) {
			this.order.deliveryAddressId = id;
			this.getDeliveryAddressesByUserId(this.currentUser.id);
		},

		submitOrder() {
			let orderDetails = [];
			for (let key in this.cart) {
				let val = this.cart[key];
				let price = val.product.unitPrice;
				if (this.customPrices[val.product.id])
					price = this.customPrices[val.product.id];
				let orderDetail = {
					productId: val.product.id,
					quantity: val.count,
					unitPrice: price,
					serviceFee: val.product.serviceFee,
				};
				orderDetails.push(orderDetail);
			}
			this.order.paymentInfo = this.payment;
			this.order.subtotal = this.subtotal;
			this.order.deliveryFee = this.deliveryFee;
			this.order.orderDetails = orderDetails;
			this.order.vatFee = 0;
			this.isLoading = true;
			backendService
				.addOrder(this.order)
				.then((response) => {
					let insertedOrder = response.data;
					this.timer(1000).then(() => {
						this.orderId = insertedOrder.id;
						this.isLoading = false;
						this.isSuccessful = true;

						this.order = {
							deliveryAddressId: null,
							subtotal: 0,
							deliveryFee: 0,
						};
						this.$store.commit("addOrder", insertedOrder);
						this.$store.commit("setCart", {});
						this.$store.commit("setCartCount", 0);
						localStorage.setItem("cart", JSON.stringify({}));
						localStorage.setItem("cartCount", 0);
					});
				})
				.catch(() => {
					this.timer(1000).then(() => {
						this.isLoading = false;
						eventBus.$emit(
							"setSnackbar",
							"Some Items might be low in stock",
							"error",
							true
						);
					});
				});
		},
		updateItemCount(key) {
			let cart = this.cart;
			let newCount = 0;
			for (key in cart) {
				newCount += cart[key].count;
			}
			this.cartCount = newCount;
			this.calculateSubtotal();
			this.calculateTotal();
			localStorage.setItem("cartCount", newCount);
			localStorage.setItem("cart", JSON.stringify(cart));
		},
	},
};
</script>
<style scoped>
.row {
	background: white;
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: #dbdbdb;
}
.image-preview {
	width: 80px;
	height: 80px;
	margin-right: 10px;
	margin-bottom: 5px;
	margin-top: 5px;
	object-fit: contain;
	float: left;
	image-rendering: smooth;
}
.title {
	font-size: 14px;
	font-weight: bold;
}
.display-price {
	font-size: 20px;
	font-weight: bold;
}
</style>
