<template>
	<v-container fluid>
		<v-form ref="form" v-model="formValid" lazy-validation>
			<v-container class="white rounded-xl mb-3">
				<v-text-field
					rounded
					outlined
					dense
					label="Title"
					v-model="value.title"
					:rules="titleRules"
					required></v-text-field>
			</v-container>
			<v-container
				fluid
				class="d-flex justify-space-between white rounded-xl mb-3">
				<v-container fluid>
					<v-text-field
						rounded
						outlined
						dense
						:rules="textRules"
						label="SKU Code"
						v-model="value.skuCode"
						required></v-text-field>
					<!-- <v-autocomplete
              rounded
              outlined
              dense
              :rules="dropdownRules"
              :items="stores"
              item-text="name"
              item-value="id"
              label="Store"
              v-model="value.storeId"
              required></v-autocomplete> -->
					<v-autocomplete
						rounded
						outlined
						dense
						:rules="dropdownRules"
						:items="brands"
						item-text="displayName"
						item-value="id"
						label="Brand Name"
						v-model="value.brandId"
						required>
						<template v-slot:no-data>
							<v-card elevation="0">
								<v-card-text class="text-center">
									<h4 class="mb-2">Brand name not found</h4>
									<v-btn
										depressed
										small
										rounded
										color="primary"
										@click="
											dialog = !dialog;
											dropdownId = brandDropdownId;
										">
										<i class="fas fa-plus mr-2"></i>
										Add Brand
									</v-btn>
								</v-card-text>
							</v-card>
						</template>
					</v-autocomplete>
					<v-autocomplete
						rounded
						outlined
						dense
						:rules="dropdownRules"
						:items="categories"
						item-text="displayName"
						item-value="id"
						label="Category"
						v-model="value.lvl1Id"
						required>
						<template v-slot:no-data>
							<v-card elevation="0">
								<v-card-text class="text-center">
									<h4 class="mb-2">Category not found</h4>
									<v-btn
										depressed
										small
										rounded
										color="primary"
										@click="
											dialog = !dialog;
											dropdownId = categoryDropdownId;
										">
										<i class="fas fa-plus mr-2"></i>
										Add Brand
									</v-btn>
								</v-card-text>
							</v-card>
						</template></v-autocomplete
					>
					<v-autocomplete
						rounded
						outlined
						dense
						:rules="dropdownRules"
						:items="productTypes"
						item-text="displayName"
						item-value="id"
						label="Type"
						v-model="value.lvl2Id"
						required>
						<template v-slot:no-data>
							<v-card elevation="0">
								<v-card-text class="text-center">
									<h4 class="mb-2">Product type not found</h4>
									<v-btn
										depressed
										small
										rounded
										color="primary"
										@click="
											dialog = !dialog;
											dropdownId = productTypeDropdownId;
										">
										<i class="fas fa-plus mr-2"></i>
										Add Brand
									</v-btn>
								</v-card-text>
							</v-card>
						</template></v-autocomplete
					>
					<v-autocomplete
						rounded
						outlined
						dense
						:items="images"
						item-text="id"
						label="Main Image"
						v-model="value.pictureId">
						<template v-slot:item="{ item }">
							<v-container
								class="py-0 my-0 d-flex align-center justify-space-around">
								<div class="subtitle-1">{{ item.id }}</div>
								<img
									class="image-preview"
									:src="getProductPictureUrlById(item.id)" />
							</v-container>
						</template>
					</v-autocomplete>
				</v-container>
				<v-container fluid>
					<v-text-field
						rounded
						outlined
						dense
						:rules="numberRules"
						label="Stock Price"
						v-model.number="value.stockPrice"
						required></v-text-field>

					<v-text-field
						rounded
						outlined
						dense
						:rules="numberRules"
						label="Quantity"
						v-model.number="value.quantity"
						required></v-text-field>
					<v-autocomplete
						rounded
						outlined
						dense
						:items="[
							{ val: true, text: 'Yes' },
							{ val: false, text: 'No' },
						]"
						item-text="text"
						item-value="val"
						label="Update Quantity Upon Order"
						v-model="value.updateQuantity">
					</v-autocomplete>
					<v-text-field
						rounded
						outlined
						dense
						:rules="numberRules"
						label="Profit in %"
						v-model.number="value.profit"
						required></v-text-field>

					<v-container
						class="d-flex align-center justify-space-around">
						<h3>Main Image Preview</h3>
						<img
							class="image-preview-lg"
							:src="getProductPictureUrlById(value.pictureId)" />
					</v-container>
				</v-container>
			</v-container>
			<v-container fluid :class="ckeditorClass" class="white rounded-xl">
				<h3 class="mb-2">Product Description</h3>
				<ckeditor
					:editor="editor"
					:config="editorConfig"
					v-model="value.description"></ckeditor>
			</v-container>
		</v-form>

		<dropdown-option-dialog
			v-model="dialog"
			:dropdown-id="dropdownId"></dropdown-option-dialog>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropdownOptionDialog from "./DropdownOptionDialog.vue";
export default {
	name: "ProductInfoForm",
	components: {
		"dropdown-option-dialog": DropdownOptionDialog,
	},
	props: {
		product: {
			type: Object,
			default: () => {
				return {};
			},
		},
		images: {
			type: Array,
			default: () => {
				return [];
			},
		},
		pFormValid: {
			type: Boolean,
			default: () => {
				return false;
			},
		},
	},
	data() {
		return {
			dialog: false,
			dropdownId: -1,
			editor: ClassicEditor,
			editorConfig: {
				toolbar: ["bold", "italic", "bulletedList", "numberedList"],
			},
			//formValid: false,
			dropdownRules: [
				(v) => {
					if (v) return true;
					return "Required";
				},
			],
			numberRules: [
				(v) => {
					if (v || v == 0) return true;
					return "Required";
				},
				(v) => {
					if (!isNaN(v)) return true;
					return "Must be numeric";
				},
			],
			textRules: [
				(v) => {
					if (v) return true;
					return "Required";
				},
			],

			titleRules: [
				(v) => {
					if (v) return true;
					return "Required";
				},
				(v) => {
					if (v?.length >= 10) return true;
					return "Length must be at least 10 characters";
				},
			],
		};
	},
	computed: {
		brands() {
			if (this.$store.getters["dropdownToOptions"]["brand"])
				return this.$store.getters["dropdownToOptions"]["brand"]
					.options;
			return [];
		},
		brandDropdownId() {
			if (this.$store.getters["dropdownToOptions"]["brand"])
				return this.$store.getters["dropdownToOptions"]["brand"].id;
			return -1;
		},
		ckeditorClass() {
			return {
				"ckeditor-error": !this.descriptionValid,
			};
		},
		descriptionValid() {
			if (this.product.description?.length != 0) return true;
			return false;
		},

		formValid: {
			get() {
				return this.pFormValid;
			},
			set(val) {
				this.$emit("form-valid", val);
			},
		},
		value: {
			get() {
				return this.product;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		categories() {
			if (this.$store.getters["dropdownToOptions"]["category"])
				return this.$store.getters["dropdownToOptions"]["category"]
					.options;
			return [];
		},
		categoryDropdownId() {
			if (this.$store.getters["dropdownToOptions"]["category"])
				return this.$store.getters["dropdownToOptions"]["category"].id;
			return -1;
		},
		productTypes() {
			if (this.$store.getters["dropdownToOptions"]["productType"])
				return this.$store.getters["dropdownToOptions"]["productType"]
					.options;
			return [];
		},
		productTypeDropdownId() {
			if (this.$store.getters["dropdownToOptions"]["productType"])
				return this.$store.getters["dropdownToOptions"]["productType"]
					.id;
			return -1;
		},
		stores() {
			return this.$store.getters["stores"];
		},
	},

	methods: {
		// updateProfitUsingDisplay() {
		//   this.product.profit =
		//     ((this.product.unitPrice - this.product.shippingFee) * 10000) /
		//       (this.product.stockPrice * (100 + this.product.tax)) -
		//     100;
		//   this.product.profit = parseFloat(this.product.profit.toFixed(2));
		// },
		// updateDisplayUsingProfit() {
		//   this.product.unitPrice =
		//     this.product.stockPrice *
		//       (1 + this.product.tax / 100) *
		//       (1 + this.product.profit / 100) +
		//     this.product.shippingFee;
		//   this.product.unitPrice = parseFloat(
		//     this.product.unitPrice.toFixed(2)
		//   );
		// },
		getProductPictureUrlById(imageId) {
			if (!imageId) return;
			return backendService.getProductPictureUrlById(imageId);
		},
		validate() {
			return this.$refs.form.validate();
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
	},
};
</script>
<style scoped>
.ckeditor-error {
	border: 1px solid lightcoral !important;
}
.image-preview {
	object-fit: contain;
	image-rendering: smooth;
	width: 60px;
	height: 60px;
}
.image-preview-lg {
	object-fit: contain;
	image-rendering: smooth;
	width: 100px;
	height: 100px;
}
</style>
