<template>
	<v-container fluid class="d-flex justify-space-between pa-0">
		<v-card class="grey lighten-3" elevation="0" width="50%">
			<v-card-text class="d-flex align-center justify-space-between">
				<h1>
					<span v-if="isUpdating">Product Detail</span
					><span v-else>New Product</span> -
					<span class="red--text">$ {{ unitPrice }}</span>
					<span v-if="serviceFee">including</span>
					<span v-if="serviceFee" class="red--text"
						>{{ serviceFee }}%</span
					>
					<span v-if="serviceFee">fee</span>
				</h1>
				<div>
					<v-btn
						v-if="!isUpdating"
						depressed
						rounded
						color="warning mr-2"
						@click="resetForm">
						<i class="fas fa-eraser mr-2"></i>
						Clear
					</v-btn>
					<v-btn
						v-if="!isUpdating"
						depressed
						rounded
						color="primary"
						@click="addProduct"
						:disabled="!formValid"
						:loading="isLoading">
						<i class="far fa-save mr-2"></i>
						Add
					</v-btn>
					<v-btn
						v-else
						depressed
						rounded
						color="primary"
						@click="updateProduct"
						:disabled="!formValid"
						:loading="isLoading">
						<i class="far fa-save mr-2"></i>
						Update
					</v-btn>
				</div>
			</v-card-text>
			<v-card-text>
				<product-info-form
					ref="product-info-form"
					class="mb-2"
					:images="existingImages"
					:product="product"
					:p-form-valid="infoFormValid"
					@form-valid="setInfoFormValid"
					v-model="product"></product-info-form>
			</v-card-text>
		</v-card>
		<v-card class="grey lighten-3" elevation="0" width="50%">
			<v-card-text>
				<image-form
					ref="image-form"
					:p-existing-images="existingImages"
					:p-new-images="newImages"></image-form>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service.js";
import ImageForm from "@/components/ImageForm.vue";
import ProductInfoForm from "@/components/ProductInfoForm.vue";
import eventBus from "@/js/event-bus";
import generalMixin from "@/js/general-mixin";
export default {
	name: "ProductForm",
	mixins: [generalMixin],
	components: {
		"image-form": ImageForm,
		"product-info-form": ProductInfoForm,
	},

	data() {
		return {
			product: {
				storeId: 1,
				skuCode: "",
				title: "",
				brandId: null,
				lvl1Id: null,
				lvl2Id: null,
				stockPrice: null,
				quantity: null,
				description: "",
				profit: null,
				unitPrice: null,
				pictureId: null,
			},
			newImages: [],
			existingImages: [],
			infoFormValid: false,
			isLoading: false,
			isUpdating: false,
			isSuccessful: false,
		};
	},
	mounted: function () {
		if (this.$route.params.id) {
			this.getUpdateForm();
		}
	},
	computed: {
		descriptionValid() {
			if (this.product.description?.length != 0) return true;
			return false;
		},
		unitPrice() {
			return (
				(this.product.stockPrice *
					(100 + this.product.profit) *
					(100 + this.serviceFee)) /
					10000 +
				0.00001
			).toFixed(2);
		},
		formValid() {
			return this.infoFormValid && this.product.description?.length > 0;
		},
		serviceFee() {
			if (!this.product.storeId) return 0;
			if (this.product.serviceFee) return this.product.serviceFee;
			let selectedStore = this.stores.find(
				(e) => e.id == this.product.storeId
			);
			if (selectedStore) {
				return selectedStore.serviceFee;
			} else {
				return 0;
			}
		},
		stores() {
			return this.$store.getters["stores"];
		},
	},
	methods: {
		addProduct() {
			if (!this.$refs["product-info-form"].validate()) {
				return;
			}
			this.isLoading = true;
			this.product.serviceFee = this.serviceFee;
			this.product.unitPrice =
				(this.product.stockPrice *
					(100 + this.product.profit) *
					(100 + this.serviceFee)) /
				10000;
			backendService
				.addProduct(this.product)
				.then((response) => {
					let files = [];
					let productId = response.data;
					this.newImages.forEach((image) => {
						let file = {
							name: image.name,
							size: image.size,
							productId: productId,
						};
						files.push(file);
					});
					if (files.length > 0) {
						backendService
							.addProductPictureInfos(files)
							.then((response) => {
								let formData = new FormData();
								for (
									let i = 0;
									i < this.newImages.length;
									i++
								) {
									let image = this.newImages[i];
									formData.append(
										"files",
										image,
										response.data[i]
									);
								}
								backendService
									.uploadProductPictures(formData)
									.then(() => {
										this.timer(1000).then(() => {
											this.resetForm();
											this.$router.replace({
												name: "ProductUpdate",
												params: { id: productId },
											});
											this.getUpdateForm();
										});
									})
									.catch(() => {
										this.isLoading = false;
									});
							})
							.catch(() => {
								this.isLoading = false;
							});
					} else {
						this.timer(1000).then(() => {
							this.resetForm();
							this.$router.replace({
								name: "ProductUpdate",
								params: { id: productId },
							});
							this.getUpdateForm();
						});
					}
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		getProductPicturesByProductId(productId) {
			backendService
				.getProductPicturesByProductId(productId)
				.then((response) => {
					this.existingImages = response.data;
				});
		},

		getProductById(id) {
			backendService.getProductById(id).then((response) => {
				this.product = response.data;
			});
		},
		getUpdateForm() {
			this.isUpdating = true;
			this.getProductById(this.$route.params.id);
			this.getProductPicturesByProductId(this.$route.params.id);
		},
		resetForm() {
			this.isLoading = false;
			this.isSuccessful = false;
			this.newImages = [];
			this.$refs["image-form"].resetForm();
			if (!this.isUpdating) {
				this.product = {
					skuCode: "",
					title: "",
					storeId: 1,
					brandId: null,
					lvl1Id: null,
					lvl2Id: null,
					stockPrice: null,
					quantity: null,
					description: "",
					profit: null,
					pictureId: null,
				};
				this.$refs["product-info-form"].resetValidation();
			}
		},
		setInfoFormValid(val) {
			this.infoFormValid = val;
		},
		updateProduct() {
			this.isLoading = true;
			this.product.serviceFee = this.serviceFee;
			this.product.unitPrice =
				(this.product.stockPrice *
					(100 + this.product.profit) *
					(100 + this.serviceFee)) /
				10000;
			backendService.updateProduct(this.product).then(() => {
				let files = [];
				for (let i = 0; i < this.newImages.length; i++) {
					let image = this.newImages[i];
					let file = {
						name: image.name,
						size: image.size,
						productId: this.product.id,
					};
					files.push(file);
				}
				if (files.length > 0) {
					backendService
						.addProductPictureInfos(files)
						.then((response) => {
							let formData = new FormData();
							for (let i = 0; i < this.newImages.length; i++) {
								let image = this.newImages[i];
								formData.append(
									"files",
									image,
									response.data[i]
								);
							}
							backendService
								.uploadProductPictures(formData)
								.then(() => {
									backendService
										.getProductPicturesByProductId(
											this.product.id
										)
										.then((response) => {
											this.existingImages = response.data;
											this.timer(1000).then(() => {
												this.isLoading = false;
												eventBus.$emit(
													"setSnackbar",
													"Product Successfully Updated!",
													"success",
													true
												);
											});
										})
										.catch(() => {
											this.isLoading = false;
										});
								})
								.catch(() => {
									this.isLoading = false;
								});
						});
				} else {
					this.timer(1000).then(() => {
						this.isLoading = false;
						eventBus.$emit(
							"setSnackbar",
							"Product Successfully Updated!",
							"success",
							true
						);
					});
				}
			});
		},
	},
	watch: {
		"$route.name": {
			handler: function (name) {
				if (name == "NewProduct") {
					this.isLoading = false;
					this.isSuccessful = false;
					this.isUpdating = false;
					this.infoFormValid = false;
					this.newImages = [];
					this.existingImages = [];
					this.product = {
						skuCode: "",
						title: "",
						categoryId: null,
						unitPrice: null,
						description: "",
						pictureId: null,
					};
					if (this.$refs["product-info-form"])
						this.$refs["product-info-form"].resetValidation();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style scoped></style>
