<template>
	<div>
		<v-card>
			<v-card-title>
				<v-progress-circular
					indeterminate
					color="primary"
					class="mr-2"></v-progress-circular>
				You are being logged out...
			</v-card-title>
		</v-card>
	</div>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";

export default {
	name: "LogoutPage",
	mixins: [generalMixin],
	data() {
		return {
			isLoading: false,
		};
	},
	mounted: function () {
		this.logout();
	},
	methods: {
		logout() {
			if (!localStorage.getItem("sessionId")) {
				this.$router.push({ name: "Home" }).catch(() => {});
				return;
			}
			backendService.logout().then(() => {
				localStorage.removeItem("fullname");
				localStorage.removeItem("username");
				localStorage.removeItem("isAdmin");
				localStorage.removeItem("sessionId");
				localStorage.removeItem("userId");
				localStorage.removeItem("session");
				localStorage.setItem("cart", "{}");
				localStorage.setItem("cartCount", 0);
				this.$store.commit("setIsLoggedIn", false);
				this.$store.commit("setIsAdmin", false);
				this.timer(3000).then(() => {
					this.$router.push({ name: "HomePage" }).catch(() => {});
				});
			});
		},
	},
};
</script>
