import { PaymentStatus } from "./enum";
import eventBus from "./event-bus";
export default {
  computed: {
    currentUser() {
      return this.$store.getters["currentUser"];
    },
    isMobile() {
      return this.$store.getters["isMobile"];
    },
    isLoggedIn() {
      return this.$store.getters["isLoggedIn"];
    },
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },

    // isMobile() {
    //   // credit to Timothy Huang for this regex test:
    //   // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    //   // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   //     return true
    //   // }
    //   // else {
    //   //   return false

    //   // }
    //   return false;
    // },
    idToOption() {
      return this.$store.getters["idToOption"];
    },
    enumToOption() {
      return this.$store.getters["enumToOption"];
    },
    userFullName() {
      if (this.isLoggedIn && this.currentUser) {
        return this.currentUser.first + " " + this.currentUser.last;
      }
      return "";
    },
  },
  methods: {
    checkIfPaid(paymentStatusId) {
			return paymentStatusId == this.getIdByEnum(PaymentStatus.Paid);
		},
    convertToUsDate(val) {
      return new Date(val).toLocaleDateString('en-US')
    },
		convertToDate(val) {
			return new Date(val).toDateString();
		},
    convertToDecimal(val) {
			return (val / 100).toFixed(2);
		},
    timer(val) {
      let promise = new Promise((resolve) => {
        setTimeout(() => {resolve()},val)
      })
      return promise;
    },
    getCustomerName(id) {
			let customer = this.customers.find((x) => x.id == id);
			if (customer) return `${customer.name} - ${customer.phone}` ;
			return "";
		},
    getIdByEnum(e){
      if(this.enumToOption && this.enumToOption[e])
        return this.enumToOption[e].id;
      return null;
    },
    getEnum(optionId) {
      if(this.idToOption && this.idToOption[optionId] && this.idToOption[optionId].customEnum){
        return this.idToOption[optionId].cutomEnum;
      }
      return null;
    },
    getOptionName(optionId) {
      if (this.idToOption && this.idToOption[optionId]) {
          return this.idToOption[optionId].displayName;
      }
      return "N/A";
    },
    successSnackbar(message) {
      eventBus.$emit(
        "setSnackbar",
        message,
        "success",
        true
      );
    },
    errorSnackbar(message) {
      eventBus.$emit(
        "setSnackbar",
        message,
        "error",
        true
      );
    },
  printReceipt(order) {
    let orderHtml = document.createElement(`div`);
    let shipHtml = document.createElement(`div`);
    let prtHtml = document.createElement(`table`);
    let totalHtml = document.createElement(`table`);
    let orderDetails = order.orderDetails;
    let deliveryAddress = order.deliveryAddress;
    prtHtml.innerHTML = `<tr><th style="width:200px;">Title</th><th style="width:20px;">Qty</th><th style="width:100px;text-align:right;">Price</th></tr>`;
    shipHtml.innerHTML = `<div>Ship To</div><div>${deliveryAddress.address1}</div><div>${deliveryAddress.address2}</div><div>${deliveryAddress.phone}</div>`;
    orderHtml.innerHTML = `<div style="font-size:18px;font-weight:bold;">Order #${
      order.id
    }</div><div style="float:inline-end;">${this.convertToDate(
      order.dateCreated
    )}</div>`;

    for (let i = 0; i < orderDetails.length; i++) {
      let orderDetail = orderDetails[i];
      let row = document.createElement("tr");
      row.innerHTML = `<td>${orderDetail.productTitle.substring(
        0,
        10
      )}</td><td style="text-align:right;">${
        orderDetail.quantity
      }</td><td style="text-align:right;">$${this.convertToDecimal(
        orderDetail.unitPrice
      ).padStart(3, " ")}</td>`;
      prtHtml.append(row);
    }
    totalHtml.innerHTML = `<tr><th style="width:50%"></th><th style="width:10%"></th><th style="width:30%"></th></tr>`;
    let row = document.createElement("tr");
    row.innerHTML = `<td></td><td style="text-align:left;">Subtotal</td><td style="text-align:right;">$${this.convertToDecimal(
      order.subtotal
    )}</td>`;
    totalHtml.append(row);
    row = document.createElement("tr");
    row.innerHTML = `<td></td><td style="text-align:left;">Delivery</td><td style="text-align:right;">$${this.convertToDecimal(
      order.deliveryFee
    )}</td>`;
    totalHtml.append(row);
    row = document.createElement("tr");
    row.innerHTML = `<td></td><td style="text-align:left;">VAT</td><td style="text-align:right;">$${this.convertToDecimal(
      order.vatFee
    )}</td>`;
    totalHtml.append(row);
    row = document.createElement("tr");
    row.innerHTML = `<td></td><td style="text-align:left;">Total</td><td style="text-align:right;">$${this.convertToDecimal(
      order.subtotal + order.deliveryFee + order.vatFee
    )}</td>`;
    totalHtml.append(row);
    // Get all stylesheets HTML

    // Open the print window
    const WinPrint = window.open("", "", "width=1000,height=400");

    WinPrint.document.write(`<!DOCTYPE html>
      <html>
      <head>
          <style>
              @font-face {
                  font-family: "ReceiptFont";
                  src: local("M PLUS Code Latin");
              }
            body{
                  padding-left:10px;
                  padding-right:10px;
                  font-size:12px;
                  font-family: "ReceiptFont" !important;
              }
          </style>
      </head>
      <body>
          <div style="width:100%;"><h1 style="text-align:center;">ចែកចាយ - Jaek Jay</h1></div>
          ${orderHtml.outerHTML}
          ${shipHtml.outerHTML}
          <table>
          ${prtHtml.innerHTML}
          </table>
          ${totalHtml.outerHTML}
      </body>
      </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  },
    
  }
};
