<template>
	<v-card
		class="grey lighten-3 rounded-xl"
		elevation="0"
		width="20%"
		height="550px">
		<v-card-text>
			<h1>Filter</h1>
		</v-card-text>
		<v-card-text>
			<v-container class="white rounded-xl">
				<v-container class="d-flex">
					<v-text-field
						class="mr-2"
						label="Min Price"
						v-model.number="filter.minPrice"
						@change="
							filter.minPrice =
								filter.minPrice != '' ? filter.minPrice : null
						"
						prefix="$"></v-text-field>
					<v-text-field
						class="ml-2"
						label="Max Price"
						v-model.number="filter.maxPrice"
						@change="
							filter.maxPrice =
								filter.maxPrice != '' ? filter.maxPrice : null
						"
						prefix="$"></v-text-field>
				</v-container>
				<v-container class="d-flex">
					<v-text-field
						class="mr-2"
						label="Min Qauntity"
						@change="
							filter.minQuantity =
								filter.minQuantity != ''
									? filter.minQuantity
									: null
						"
						v-model.number="filter.minQuantity"></v-text-field>
					<v-text-field
						class="ml-2"
						label="Max Qauntity"
						@change="
							filter.maxQuantity =
								filter.maxQuantity != ''
									? filter.maxQuantity
									: null
						"
						v-model.number="filter.maxQuantity"></v-text-field>
				</v-container>
				<v-container>
					<v-autocomplete
						label="Brand"
						:items="brands"
						item-text="displayName"
						item-value="id"
						v-model.number="filter.brandId"></v-autocomplete>
				</v-container>

				<v-container>
					<v-autocomplete
						label="Category"
						:items="categories"
						item-text="displayName"
						item-value="id"
						v-model.number="filter.lvl1"></v-autocomplete>
				</v-container>
				<v-container>
					<v-autocomplete
						label="Type"
						:items="productTypes"
						item-text="displayName"
						item-value="id"
						v-model.number="filter.lvl2"></v-autocomplete>
				</v-container>
				<v-container
					><v-autocomplete
						label="Status"
						v-model="filter.isActive"></v-autocomplete
				></v-container>

				<v-container fluid class="d-flex justify-end">
					<v-btn
						color="primary"
						depressed
						rounded
						@click="emitEvent"
						:loading="isLoading">
						<i class="fas fa-sync-alt mr-2"></i>
						Apply
					</v-btn>
				</v-container>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<script>
import generalMixin from "@/js/general-mixin";
export default {
	name: "FilterPanel",
	mixins: [generalMixin],
	data() {
		return { isLoading: false, filter: {} };
	},
	computed: {
		brands() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["brand"]
			) {
				return this.$store.getters["dropdownToOptions"]["brand"]
					.options;
			}
			return [];
		},
		categories() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["category"]
			) {
				return this.$store.getters["dropdownToOptions"]["category"]
					.options;
			}
			return [];
		},
		productTypes() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["productType"]
			) {
				return this.$store.getters["dropdownToOptions"]["productType"]
					.options;
			}
			return [];
		},
	},
	methods: {
		emitEvent() {
			this.isLoading = true;
			this.$emit("handle-event", this.filter);
			this.timer(1000).then(() => {
				this.isLoading = false;
			});
		},
	},
};
</script>
