export default {
  data() {
    return {
      message:"",
      description: "",
      first: "",
      last: "",
      name: "",
      email: "",
      phone:"",
      password: "",
      confPassword: "",
      isSuccessful: false,
      isLoading: false,
      isFormValid: false,
      title:"",
      username: "",
      emailRules: [
        (v) => {
          if (v) return true;
          return "Required";
        },
        (v) => {
          let regex = /^\w[\w\d!#$%^&*.\-_]+@[a-zA-Z]+\.[a-zA-Z]+$/;
          if (v && v.match(regex)) return true;
          return "Email is not in the right format";
        },
      ],
      nameRules: [
        (v) => {
          if (v) return true;
          return "Required";
        },
        (v) => {
          if (v?.length >= 1) return true;
          return "Length must be at least 1 characters";
        },
        (v) => {
          let regex = /[$&+,:;=?@#|'<>.^*()%!\-0-9]/;
          if (v && !v.match(regex)) return true;
          return "Cannot contain any special characters";
        },
      ],
      phoneRules: [
        (v) => {
          if (v) return true;
          return "Required";
        },
        (v) => {
          if (v?.length >= 9) return true;
          return "Length must be at least 9 digits";
        },
        (v) => {
          let regex = /[$&+,:;=?@#|'<>.^*()%!\-a-z]/;
          if (v && !v.match(regex)) return true;
          return "Must contain only numbers";
        },
      ],
      textRules: [
        (v) => {
          if (v) return true;
          return "Required";
        },
      ],
      passRules: [
        (v) => {
          if (v) return true;
          return "Required";
        },
        (v) => {
          if (v?.length >= 8) return true;
          return "Length must be at least 8 characters";
        },
        (v) => {
          let regex = /[$&+,:;=?@#|'<>.^*()%!\-0-9]/;
          if (v && v.match(regex)) return true;
          return "Password must contain one special character or number";
        },
        (v) => {
          if (v == this.password) return true;
          return "Password doesn't match";
        },
      ],
      titleRules: [
        (v) => {
          if(v) return true;
          return "Required";

        },
        (v) => {
          if(v?.length >=10) return true;
          return "Length must be at least 10 characters"; 
        }
      ]
    };
  },
  computed: {},
  methods: {
    clearVariables() {
     
      this.description="";
      this.email = "";
      this.first = "";
      this.last = "";
      this.name="";
      
      this.password = "";
      this.confPassword = "";
      this.username = "";
      this.isSuccessful = false;
      this.isLoading = false;
      this.isFormValid = false;
    },
  },
};
