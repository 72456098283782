
const ShipmentGroupStatus = {
    New:4
}
const ShipmentStatus = {
    New:3
}
const PaymentStatus = {
    Pending:1,
    Paid:2
}
const InvoiceType = {
    Shipment:7,
    Order:8,
    General:11,
}
const PaymentType = {
    Aba:9,
    Cash:10
}
export  {
    ShipmentGroupStatus,
    ShipmentStatus,
    PaymentStatus,
    PaymentType,
    InvoiceType
}