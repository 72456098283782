<template>
	<v-container
		fluid
		class="d-flex justify-space-around align-center white my-5 rounded-xl w-75">
		<v-card elevation="0" width="100%">
			<v-card-title>Login</v-card-title>
			<v-card-text>
				<v-form v-model="isFormValid" ref="form">
					<v-row>
						<v-col>
							<v-text-field
								:rules="emailRules"
								label="Email"
								v-model="email"
								v-on:keyup.enter="login()"
								required></v-text-field>
						</v-col>
					</v-row>
					<v-row
						><v-col>
							<v-text-field
								:rules="passRules"
								type="password"
								label="Password"
								v-model="password"
								v-on:keyup.enter="login()"
								required></v-text-field> </v-col
					></v-row>
				</v-form>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn
					class="primary"
					depressed
					rounded
					@click="login"
					:disabled="!isFormValid"
					:loading="isLoading">
					<i class="fas fa-sign-in-alt mr-2"></i>
					Login
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-card elevation="0" v-if="!isMobile">
			<img src="@/assets/23-going-online.png" />
		</v-card>
	</v-container>
</template>
<script>
import eventBus from "@/js/event-bus.js";
import backendService from "@/services/backend-service.js";
import formMixin from "@/js/form-mixin.js";
import generalMixin from "@/js/general-mixin";
export default {
	name: "LoginPage",
	mixins: [formMixin, generalMixin],
	data() {
		return {};
	},
	computed: {},
	methods: {
		login() {
			if (!this.$refs.form.validate()) {
				return;
			}
			let loginInfo = {};
			loginInfo.email = this.email;
			loginInfo.password = this.password;
			this.isLoading = true;
			backendService
				.login(loginInfo)
				.then((response) => {
					localStorage.setItem(
						"session",
						JSON.stringify(response.data)
					);
					localStorage.setItem(
						"userInfo",
						JSON.stringify(response.data.userInfo)
					);
					localStorage.setItem(
						"isAdmin",
						response.data.userInfo.isAdmin
					);
					localStorage.setItem("sessionId", response.data.id);
					this.$store.commit(
						"setCurrentUser",
						response.data.userInfo
					);
					if (response.data.userInfo.isAdmin) {
						this.$store.dispatch("getDropdownToOptions");
					}
					this.timer(1000).then(() => {
						this.$router.push({ name: "HomePage" });
						this.isLoading = false;
					});
				})
				.catch(() => {
					this.timer(1000).then(() => {
						eventBus.$emit(
							"setSnackbar",
							"Incorrect Username or Password",
							"red darken-1 white--text",
							true
						);
						this.isLoading = false;
					});
				});
		},
	},
};
</script>
<style scoped></style>
