<template>
	<v-container fluid class="pa-0">
		<v-card elevation="0" class="grey lighten-3" v-if="!isLoading">
			<v-card-text class="white">
				<v-container class="d-flex justify-space-between align-center">
					<img
						src="@/assets/mtl-cargo-logo-2.png"
						style="width: 120px" />

					<h2
						style="font-family: 'Sora', sans-serif"
						class="grey--text text-ligten-1">
						MTL Cargo - Chuyen Hang Ve VN
					</h2>
				</v-container></v-card-text
			>
			<v-card-text class="white">
				<v-container class="d-flex align-center justify-space-between">
					<h1 class="mr-5">
						{{ getOptionName(invoice.typeId) }} Invoice
					</h1>
					<v-btn
						id="print-invoice"
						color="primary"
						depressed
						rounded
						@click="print()"
						><i class="fas fa-cubes mr-2"></i>Print</v-btn
					>
				</v-container>
			</v-card-text>
			<v-card-text class="white">
				<v-container fluid class="d-flex justify-space-around px-0">
					<v-container>
						<h4>Invoice #</h4>
						<div>{{ invoice.id }}</div>
					</v-container>

					<v-container>
						<h4>Date Created</h4>
						<div>{{ convertToUsDate(invoice.dateCreated) }}</div>
					</v-container>
					<v-container>
						<h4>Amount</h4>
						<div>$ {{ invoice.totalAmount.toFixed(2) }}</div>
					</v-container>
					<v-container>
						<h4>Payment Status</h4>
						<div>
							{{ getOptionName(invoice.paymentStatusId) }}
							<span v-if="invoice.paymentStatusId == paid"
								>- {{ convertToUsDate(paymentDate) }}</span
							>
						</div>
					</v-container>
					<v-container>
						<h4>MTL Tracking #</h4>
						<div v-if="invoice.mtlTracking">
							{{ invoice.mtlTracking }}
						</div>
						<div v-else>N/A</div>
					</v-container>
				</v-container>
			</v-card-text>

			<v-card-text class="d-flex justify-space-between white">
				<v-container>
					<h4>Sender Info</h4>
					<div>{{ getCustomerName(shipment.senderId) }}</div>
					<div>{{ shipment.senderAddress1 }}</div>
					<div>{{ shipment.senderAddress2 }}</div>
					<div>{{ shipment.senderPhone }}</div>
				</v-container>
				<v-container
					><h4>Receiver Info</h4>
					<div>
						{{ getCustomerName(shipment.receiverId) }}
					</div>
					<div>{{ shipment.receiverAddress1 }}</div>
					<div>{{ shipment.receiverAddress2 }}</div>
					<div>{{ shipment.receiverPhone }}</div>
				</v-container>
				<v-container
					><h4>Shipment Description</h4>
					<div v-if="shipment.description">
						{{ shipment.description }}
					</div>
					<div v-else>N/A</div>
					<h4>Invoice Description</h4>
					<div v-if="invoice.description">
						{{ invoice.description }}
					</div>
					<div v-else>N/A</div>
				</v-container>
			</v-card-text>

			<v-card-text class="d-flex justify-space-between white">
				<v-container>
					<h4>Number of Boxes</h4>
					<div>{{ shipment.totalBox }}</div>
				</v-container>
				<v-container>
					<h4>Total Weight</h4>
					<div>
						<span class="mr-2">{{ shipment.totalWeight }}</span
						><span>lbs</span>
					</div>
				</v-container>
				<v-container>
					<h4>Total Value</h4>
					<div>
						<span class="mr-2">$</span
						><span>{{ shipment.totalValue }}</span>
					</div>
				</v-container>
			</v-card-text>
			<v-card-text class="white">
				<v-container class="white" fluid>
					<v-container fluid class="mb-2"
						><h2>Charge List</h2></v-container
					>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Item</th>
									<th class="text-right">Quantity</th>
									<th class="text-right">Unit Price</th>
									<th class="text-right">Line Total</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(
										invoiceDetail, idx
									) in invoiceDetails"
									:key="`invoice-detail-${idx}`">
									<td>{{ invoiceDetail.name }}</td>
									<td class="text-right">
										{{ invoiceDetail.quantity }}
									</td>
									<td class="text-right">
										${{
											invoiceDetail.unitPrice.toFixed(2)
										}}
									</td>
									<td class="text-right">
										${{
											(
												invoiceDetail.quantity *
												invoiceDetail.unitPrice
											).toFixed(2)
										}}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-container>
			</v-card-text>
		</v-card>
		<v-card v-else elevation="0">
			<v-card-text class="d-flex justify-center">
				<v-progress-circular
					indeterminate
					color="primary"></v-progress-circular>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import eventBus from "@/js/event-bus";
import backendService from "@/services/backend-service";
import generalMixin from "@/js/general-mixin.js";
import { InvoiceType, PaymentStatus } from "@/js/enum";
export default {
	name: "InvoiceForm",
	mixins: [generalMixin],
	components: {},
	mounted: function () {
		setTimeout(() => {
			this.isLoading = false;
		}, 500);
		if (this.$route.params.id) {
			this.getUpdateForm();
		}
	},
	props: {
		type: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			shipment: {},
			paymentDialog: false,
			cashDialog: false,
			digitalDialog: false,
			originalInvoice: {},
			isUpdating: false,
			isPaymentLoading: false,
			isLoading: true,
			invoiceDetails: [],
			paymentStatusId: null,
			invoice: {
				typeId: null,
				customerId: null,
				associatedId: null,
				description: "",
				totalAmount: 0,
				payment: {},
				paymentStatusId: null,
			},
			totalAmount: 0,
		};
	},
	computed: {
		customers() {
			return this.$store.getters["customers"];
		},
		shipments() {
			return this.$store.getters["shipments"];
		},
		items() {
			if (this.invoice.typeId == this.getIdByEnum(InvoiceType.Shipment))
				return this.shipments;
			else if (this.invoice.typeId == this.getIdByEnum(InvoiceType.Order))
				return this.orders;
			return [];
		},
		paid() {
			return this.getIdByEnum(PaymentStatus.Paid);
		},
		orders() {
			return this.$store.getters["orders"];
		},
		invoiceTypes() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["invoiceType"]
			)
				return this.$store.getters["dropdownToOptions"]["invoiceType"]
					.options;
			return [];
		},
		isShipment() {
			return (
				this.invoice.typeId == this.getIdByEnum(InvoiceType.Shipment)
			);
		},
		paymentStatuses() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["paymentStatus"]
			)
				return this.$store.getters["dropdownToOptions"]["paymentStatus"]
					.options;
			return [];
		},
		paymentTypes() {
			if (
				this.$store.getters["dropdownToOptions"] &&
				this.$store.getters["dropdownToOptions"]["paymentType"]
			)
				return this.$store.getters["dropdownToOptions"]["paymentType"]
					.options;
			return [];
		},
		paymentDate() {
			if (this.invoice.payment && this.invoice.payment.dateCreated)
				return this.convertToDate(
					this.invoice.payment.dateCreated.split("T")
				);
			return "";
		},
	},
	methods: {
		addInvoiceDetail() {
			this.invoiceDetails.push({
				isActive: true,
				name: "",
				unitPrice: 0,
				quantity: 0,
				lineTotal: 0,
			});
		},
		addInvoice() {
			this.isLoading = true;
			this.invoice.invoiceDetails = this.invoiceDetails;
			backendService
				.addInvoice(this.invoice)
				.then((response) => {
					this.timer(1000).then(() => {
						this.invoice.id = response.data;
						this.isLoading = false;
						this.isUpdating = true;
						this.$router.push({
							name: "InvoiceUpdate",
							params: { id: response.data },
						});
						eventBus.$emit(
							"setSnackbar",
							"Successfully added a new invoice",
							"success",
							true
						);
					});
				})
				.catch(() => {
					this.timer(1000).then(() => {
						this.isLoading = false;
					});
				});
		},
		deleteInvoiceDetail(idx) {
			this.invoiceDetails.splice(idx, 1);
		},
		getCustomerName(id) {
			let item = this.customers.find((x) => x.id == id);
			if (item) return `${item.name} - ${item.phone}`;
			return "";
		},
		getUpdateForm() {
			this.getInvoiceById(this.$route.params.id);
		},
		getInvoiceById(id) {
			backendService.getInvoiceById(id).then((response) => {
				this.invoice = response.data;
				this.invoiceDetails = this.invoice.invoiceDetails;
				this.paymentStatusId = this.invoice.paymentStatusId;
				if (this.invoice.mtlTracking)
					backendService
						.getShipmentByTracking(this.invoice.mtlTracking)
						.then((response) => {
							this.shipment = response.data;
						});
			});
		},

		print() {
			window.print();
		},
		undoRemoveInvoiceDetail(idx) {
			this.invoiceDetails[idx].isActive = true;
			this.updateTotalAmount();
		},
		removeInvoiceDetail(idx) {
			this.invoiceDetails[idx].isActive = false;
			this.updateTotalAmount();
		},
		updateInvoice() {
			this.isLoading = true;
			this.invoice.invoiceDetails = this.invoiceDetails;
			backendService
				.updateInvoice(this.invoice)
				.then((response) => {
					this.timer(1000).then(() => {
						this.invoice = response.data;
						this.invoiceDetails = this.invoice.invoiceDetails;
						this.originalInvoice = JSON.stringify(this.invoice);
						this.isLoading = false;
						eventBus.$emit(
							"setSnackbar",
							"Successfully updated invoice",
							"success",
							true
						);
					});
				})
				.catch(() => {
					this.timer(1000).then(() => {
						this.isLoading = false;
					});
				});
		},
		updateTotalAmount() {
			this.invoice.totalAmount = this.invoiceDetails.reduce((ps, a) => {
				if (a.isActive)
					return (
						ps + (a.unitPrice * 100 * (a.quantity * 100)) / 10000
					);
				return ps;
			}, 0);
		},
	},
	watch: {
		"$route.name": {
			handler: function (name) {
				if (name == "NewInvoice") {
					this.isLoading = false;
					this.isUpdating = false;
					this.totalAmount = 0;
					this.invoiceDetails = [];
					this.invoice = {
						typeId: null,
						customerId: null,
						associatedId: null,
						description: "",
						totalAmount: 0,
						payment: {},
					};
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style scoped>
@media print {
	#print-invoice {
		display: none;
	}
}
</style>
