<template>
	<v-container fluid class="d-flex justify-space-between pa-0">
		<filter-panel @handle-event="applyFilter"></filter-panel>
		<v-card
			elevation="0"
			width="80%"
			height="790px"
			class="overflow-y-auto grey lighten-3">
			<v-card-text>
				<v-container>
					<v-container
						class="d-flex justify-space-between white rounded-xl mb-2"
						v-for="product in products"
						:key="product.id">
						<product-card
							showQty
							:product="product"
							:allow-add-to-cart="true"></product-card>
						<v-container fluid class="my-0 py-0">
							<v-container class="d-flex justify-space-between">
								<div class="red--text">
									<h3>Average Stock Price</h3>
								</div>
								<div>
									<h3>
										${{ findAvgFromStocks(product.id) }}
									</h3>
								</div>
							</v-container>
							<v-simple-table height="250px">
								<thead>
									<tr>
										<th class="text-left">Id</th>
										<th class="text-left">Quantity</th>
										<th class="text-left">Stock Price</th>
										<th class="text-left">Created By</th>
										<th calss="text-left">Date Created</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="stock in productIdToStocks[
											product.id
										]"
										:key="`${product.id}-stock-${stock.id}`">
										<td>{{ stock.id }}</td>
										<td>{{ stock.quantity }}</td>
										<td>
											${{ stock.stockPrice.toFixed(2) }}
										</td>
										<td>
											{{ stock.createdBy.first }}
											{{ stock.createdBy.last }}
										</td>
										<td>
											{{
												convertToDate(stock.dateCreated)
											}}
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</v-container>

						<v-container fluid class="d-flex flex-column w-25">
							<v-btn
								class="mb-2"
								color="warning"
								depressed
								rounded
								@click="
									$router.push({
										name: 'ProductUpdate',
										params: { id: product.id },
									})
								">
								<i class="fas fa-pencil-alt mr-2"></i>
								Update
							</v-btn>
							<v-btn
								class="mb-2"
								color="warning"
								depressed
								outlined
								rounded
								@click="addStock(product)">
								<i class="fas fa-people-carry mr-2"></i>
								Add Stock
							</v-btn>
							<v-btn
								class="mb-2"
								color="warning"
								depressed
								outlined
								rounded
								@click="getStocksByProductId(product.id)">
								<i class="fas fa-download mr-2"></i>
								Get Stock History
							</v-btn>
							<v-btn
								color="error"
								depressed
								outlined
								rounded
								@click="openDeleteDialog(product.id)"
								:loading="product.id == isDeleteLoadingIdx">
								<i class="fa-solid fa-x mr-2"></i>
								Remove
							</v-btn>
						</v-container>
					</v-container>
				</v-container>
			</v-card-text>
		</v-card>
		<in-stock-dialog
			:p-product="selectedProduct"
			v-model="inStockDialog"
			@handle-event="
				getStocksByProductId(selectedProduct.id)
			"></in-stock-dialog>
		<general-dialog
			:dialog="deleteDialog"
			:options="deleteOptions"
			v-model="deleteDialog"
			@handle-event="deleteProduct"></general-dialog>
		<v-overlay v-model="isLoading">
			<v-progress-circular
				indeterminate
				color="primary"></v-progress-circular>
		</v-overlay>
	</v-container>
</template>
<script>
import backendService from "@/services/backend-service";
import ProductCard from "@/components/ProductCard.vue";
import InStockDialog from "@/components/InStockDialog.vue";
import FilterPanel from "@/components/FilterPanel.vue";
import generalMixin from "@/js/general-mixin";
import GeneralDialog from "@/components/GeneralDialog.vue";
import eventBus from "@/js/event-bus";
export default {
	name: "StorePage",
	mixins: [generalMixin],
	mounted: function () {
		this.store.id = this.$route.params.id;
		this.getProducts({});
	},
	components: {
		"product-card": ProductCard,
		"in-stock-dialog": InStockDialog,
		"filter-panel": FilterPanel,
		"general-dialog": GeneralDialog,
	},
	data() {
		return {
			tmpStocks: [],
			productIdToStocks: {},
			selectedProduct: {},
			products: [],
			store: {},
			isLoadingIdx: -1,
			isDeleteLoadingIdx: -1,
			isLoading: false,
			inStockDialog: false,
			toDeleteProduct: -1,
			deleteDialog: false,
			deleteOptions: {
				btnText: "Yes",
				message: "This action is not revertable!",
				title: "Are you sure you want to delete this product?",
			},
		};
	},
	computed: {
		cart() {
			return this.$store.getters["cart"];
		},
		cartCount() {
			return this.$store.getters["cartCount"];
		},
	},
	methods: {
		addStock(product) {
			this.selectedProduct = product;
			this.inStockDialog = true;
		},
		applyFilter(filter) {
			this.getProducts(filter);
		},
		findAvgFromStocks(productId) {
			if (
				productId &&
				this.productIdToStocks[productId] &&
				this.productIdToStocks[productId].length
			) {
				let stocks = this.productIdToStocks[productId];
				let sum = 0;
				sum = stocks.reduce(
					(accumulator, stock) => accumulator + stock.stockPrice,
					0
				);
				return (sum / stocks.length).toFixed(2);
			}
			return (0).toFixed(2);
		},
		getProducts(filter) {
			this.isLoading = true;
			backendService.getProducts(filter).then((response) => {
				this.productIdToStocks = {};
				this.timer(1000).then(() => {
					this.isLoading = false;
					this.products = response.data;
					this.products.forEach((product) => {
						this.productIdToStocks[product.id] = [];
					});
				});
			});
		},
		getStocksByProductId(productId) {
			backendService.getStocksByProductId(productId).then((response) => {
				this.productIdToStocks[productId] = response.data;
				this.$forceUpdate();
			});
		},
		getStoreById(storeId) {
			backendService.getStoreById(storeId).then((response) => {
				this.store = response.data;
			});
		},
		openDeleteDialog(productId) {
			this.toDeleteProduct = productId;
			this.deleteDialog = true;
		},
		// updateStock(val) {
		// 	this.selectedProduct.quantity = val.quantity;
		// },
		deleteProduct() {
			this.deleteDialog = false;
			this.isDeleteLoadingIdx = this.toDeleteProduct;
			backendService
				.deleteProductById(this.toDeleteProduct)
				.then(() => {
					setTimeout(() => {
						this.products = this.products.filter(
							(x) => x.id != this.toDeleteProduct
						);
						this.toDeleteProduct = -1;
						this.isDeleteLoadingIdx = -1;
						eventBus.$emit(
							"setSnackbar",
							"Successfully delete the product",
							"success",
							true
						);
					}, 1000);
				})
				.catch(() => {
					setTimeout(() => {
						this.isDeleteLoadingIdx = -1;
						this.toDeleteProduct = -1;
						eventBus.$emit(
							"setSnackbar",
							"Ops!, something is not right!",
							"error",
							true
						);
					}, 1000);
				});
		},
	},
};
</script>
