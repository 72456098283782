<template>
     <v-container fluid class="pa-0">
          <v-data-table
               :headers="headers"
               :items="shipmentGroups"
               :items-per-page="15"
               class="elevation-1">
               <template v-slot:item="{ item }">
                    <tr>
                         <td>
                              <a
                                   @click="
                                        $router.push({
                                             name: 'ShipmentGroupUpdate',
                                             params: { id: item.id },
                                        })
                                   "
                                   >{{ item.id }}</a
                              >
                         </td>
                         <td>{{ convertToDate(item.shipmentDate) }}</td>
                         <td>{{ item.totalBox }}</td>
                         <td>{{ item.totalWeight }}</td>
                         <td>{{ item.totalValue }}</td>
                         <td>{{ getOptionName(item.statusId) }}</td>
                    </tr>
               </template></v-data-table
          >
     </v-container>
</template>
<script>
import generalMixin from "@/js/general-mixin";
import backendService from "@/services/backend-service";
export default {
     name: "ShipmentGroupList",
     mixins: [generalMixin],
     data() {
          return {
               dialog: false,
               shipmentDate: null,

               headers: [
                    {
                         text: "Id",
                         align: "start",
                         sortable: false,
                         value: "id",
                    },
                    { text: "Shipment Date", value: "shipmentDate" },
                    { text: "Total Boxes", value: "totalBox" },
                    { text: "Total Weight", value: "totalWeight" },
                    { text: "Total Value", value: "totalValue" },
                    { text: "Status", value: "statusId" },
               ],
          };
     },
     mounted: function () {},
     computed: {
          shipmentGroups() {
               return this.$store.getters["shipmentGroups"];
          },
     },
     methods: {
          addShipmentGroup() {
               let shipmentGroupInfo = {};
               shipmentGroupInfo.shipmentDate = this.shipmentDate;
               backendService
                    .addShipmentGroup(shipmentGroupInfo)
                    .then((response) => {
                         this.dialog = false;
                         shipmentGroupInfo.id = response.data;
                         shipmentGroupInfo.totalWeight = 0;
                         shipmentGroupInfo.totalBox = 0;
                         shipmentGroupInfo.totalValue = 0;
                         this.shipmentGroups.push(shipmentGroupInfo);
                    });
          },
     },
};
</script>
