<template>
  <v-dialog v-model="value" max-width="290">
    <v-card>
      <v-card-title class="text-h5">
        {{ options.title }}
      </v-card-title>
      <v-card-text>{{ options.message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" depressed rounded @click="emitEvent">
          <i class="fas fa-check mr-2"></i>{{ options.btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "GeneralDialog",
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    options: {
      type: Object,
      default: () => {
        return {
          btnText: "Text",
          message: "System Default",
          title: "System Default",
        };
      },
    },
  },
  data() {
    return {};
  },
  created: function () {},

  computed: {
    value: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    emitEvent() {
      this.value = false;
      this.$emit("handle-event");
    },
  },
};
</script>
<style scoped>
.card-non-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  min-width: 50%;
}
.card-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  min-width: 80%;
}
.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
</style>
